
import React, { Fragment, useEffect, useState } from 'react';
import { router } from '../../routes';
import {  SearchIcon,} from '@heroicons/react/solid';
import { Providerswebsite } from '@adv/sdk/Industry';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { Link, useRouteParams } from 'react-typesafe-routes';
import { useUser } from '../../components/organisms/Layout/Layout';
const Websites = () => {
  const userData  = useUser();
  const options = useRouteParams(router.providers);
  const [website, setwebsite] = useState<any[]>([])
const getallwebsite = async () => {
  const { data } = await Providerswebsite();
  setwebsite(data);
  console.log(data);

}
function limit(string = '', limit = 0) {
  return string.substring(0, limit)
}
useEffect(() => {
  getallwebsite();
}, []);
return (
<div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
    <div className="bg-gray-800 pt-3">
        <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 p-4 shadow text-2xl ">
            <h3 className="font-bold pl-2 mt-2">{options.name}'s Websites</h3>
        </div>
    </div>
<div className='flex bg-gray-200 py-2'>
  <div className='flex-1 flex'>
    <div className='relative'>
      <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2' />
      <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
    </div>
  </div>
  <div className='flex-1  text-right pr-3'>
      <input type='date' className='ml-2 border border-gray-200 rounded-md text-xs'></input>
    </div>
</div>
  <div className="flex flex-wrap overflow-y-scroll">
    {website?.length == 0 ?
      'Loading...'
      :<>
    {website.map((web,i)=>(
      web.provider?
      (web.provider==options.id?
        <div className="w-full md:w-1/2 xl:w-1/4 p-6 capitalize">
            <div className='flex rounded-2xl overflow-hidden shadow-md'>
              <div className='flex-1 bg-gradient-to-r from-black to-gray-700 py-2 pl-4 relative text-white relative'>
                <div className='text-base mt-3  flex bg-green'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-11 w-11" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                  <div className='text-md flex-1  mr-2 text-left pl-3' title={web.name}>
                  {web.name.length<=16? web.name:<>{limit(web.name, 16)}...</>}
                    <div className='text-xs '>{format(new Date(web.createdAt), 'dd-MM-yyyy')}</div>
                  </div>
                </div>
                <div className='text-xs flex my-1 mt-2'>

                </div>
                <div className='text-lg flex my-1'>
                  <div className='w-1/3 '>pages</div>
                  <div className='w-3/4 text-xs mt-1'>
                    <a href={web.pages?.home} className='mb-1 inline-block border-blue-50 border-b' target="_blank">Home page</a><br />
                    <a href={web.pages?.srp} className='mb-1 inline-block border-blue-50 border-b' target="_blank">Search Result</a><br />
                    {/* <a href={web.pages?.vdp} className='mb-1 inline-block border-blue-50 border-b' target="_blank">Vehicle Listing</a><br />
                    <a href={web.pages?.vlp} className='mb-1 inline-block border-blue-50 border-b' target="_blank">Vehicle Details</a> */}
                  </div>
                </div>
                <div className='text-sm flex my-1'>
                  <div className='w-1/3  '>provider</div><div className='w-3/4 '>Fox Dealer</div>
                </div>
                <button className="bg-white text-black text-xs px-4 py-1 rounded-lg relative right-3 top-1  mb-2 shadow-lg mt-3 ml-2">
                  Details
                </button>
              </div>
              <div className=' bg-white py-2 px-1 flex flex-col'>
                <button title="edit" className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                </button>
                <button title="embed code" className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                  </svg>
                </button>
                <button title="delete" className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
        </div>
        :'')
        :''
    ))}</>}
    </div>
</div>
  );
};

export default Websites;
