import {
  OptionsRouter,
  Redirect,
  RouteMiddleware,
  stringParser,
  intParser,
} from 'react-typesafe-routes';
import { number, string } from 'yup/lib/locale';
import Cookies from 'js-cookie';
import Dashboard from '../features/Dashboard';
import Subscriptions from '../features/Subscriptions';
import Industries from '../features/Industries';
import Providers from '../features/Providers';
import Websites from '../features/Websites';
import WebsitesAll from '../features/WebsitesAll';
import Users from '../features/Users';
import Industrymetadata from '../features/Industrymetadata';
import IndustryRegistry from '../features/IndustryRegistry';
import IndustryEvents from '../features/IndustryEvents';
import Schema from '../features/Schema';
import WebsiteMetadata from '../features/WebsiteMetadata';
import Organizations from '../features/Organizations';
import OrganizationsPermisions from '../features/OrganizationsPermisions';
import Lambda from '../features/Lambda';

const defaultOptions = {
  appBar: true,
};

const isAuthenticated = checkTokenInCookies();
// Cookies.set('token',
// ''
// )
const AuthMiddleware: RouteMiddleware = (next: any) => {
  // if (isAuthenticated) {
    return next;

  // } else { 
  //   // return next;    
  //  console.log('Console ~ next', next);
  //   window.location.href =    process.env.NX_APPS_URL+'/login';
  //   // return <Redirect to={router.login()} />;
  // }
};

export const router = OptionsRouter(defaultOptions, (route) => ({
  dashboard: route('/dashboard', {
    component: Dashboard,
    middleware: AuthMiddleware,
  }),
  subscriptions: route('subscriptions', {
    component: Subscriptions,
  }),
  industries: route('industries', {
    component: Industries,
  }),
  providers: route('providers/:id/:name', {
    component: Providers,
    params: { id:stringParser,name:stringParser  },
  }),
  IndustryRegistry: route('industryregistry', {
    component: IndustryRegistry,
  }),
  IndustryEvents: route('IndustryEvents', {
    component: IndustryEvents,
  }), 
  Schemas: route('Schemas/:industryId', {
    component: Schema, 
    params: { industryId:stringParser},
  }),
  IndustryEventsById: route('IndustryEvents/:industryId', {
    component: IndustryEvents,
    params: { industryId:stringParser},
  }),
  websites: route('websites/:id/:name', {
    component: Websites,
    params: { id:stringParser,name:stringParser  },
  }),
  WebsitesAll: route('websites', {
    component: WebsitesAll,
  }),
  WebsiteMetadata: route('WebsiteMetadata/:id/:name/:provider', {
    component: WebsiteMetadata,
    params: { id:stringParser,name:stringParser,provider:stringParser},
  }),
  users: route('/users', {
    component: Users,
  }),
  organizations: route('organizations', {
    component: Organizations,
  }),
  OrganizationsPermisions: route('OrganizationsPermisions/:orgID/:orgName', {
    component: OrganizationsPermisions,
    params: { orgID:stringParser,orgName:stringParser},
  }),
  Lambda: route('Lambda/:industryID/:industryName', {
    component: Lambda,
    params: { industryID:stringParser,industryName:stringParser},
  }),
  // industrymetadata: route('industrymetadata/:id/:name', {
  //   component: Industrymetadata,
  //   params: { id:stringParser,name:stringParser  },
  // }),
  industrymetadata: route('/', {
    component: Industrymetadata,
  }),
  // industrymetadata: route('industrymetadata', {
  //   component: Industrymetadata,
  // }),
}));

function checkTokenInCookies() {
  return Cookies.get('token');
  // throw new Error('Function not implemented.');
}