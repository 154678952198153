
import React, { Fragment, useEffect, useState } from 'react';

import {  SearchIcon,  UserAddIcon,} from '@heroicons/react/solid';
import Cookies from 'js-cookie';
import { AdvButton } from '@adv/ui';
import { router } from '../../routes';
import { useNotifications } from "@mantine/notifications";
import { Link, useRouteActive } from 'react-typesafe-routes';
import { MultiSelect,Select,Checkbox  } from '@mantine/core';
import { Dialog, Transition } from '@headlessui/react'
import Tablerow from './tablerow';
import { UserList,adminCreateUser,userRoles,getuserbymail,createOrganization,UserListbyorg ,Userdetails,allOrganizations} from '@adv/sdk/user';
import { FetchAllIndustries,CreateIndustry,Providerswebsite } from '@adv/sdk/Industry';
import { getpermisions,getpermisionsbymodule} from '@adv/sdk/permissions';
const Users = () => {
  const notifications = useNotifications();
const [create, setCreate] = useState(false)
const [roleslist, setRoleslist] = useState<any[]>([])
const [websiteList, setWebsiteList] = useState<any[]>([]) //all websites included
const [selectedwebsiteList, setSelectedWebsiteList] = useState<any[]>([]) //list after filter by org
const [industriesList, setIndustriesList] = useState<any[]>([])
const [allOrg, setallOrg] = useState<any>([])
const [user, setUser] = useState<any>([])
const [curentUserroles, setcurentUserroles] = useState('')
const [users, setUsers] = useState<any[]>([])
//uservariables
const [email, setemail] = useState('')
const [password, setpassword] = useState('')
const [name, setname] = useState('')
const [secondName, setsecondName] = useState('')
const [phone, setPhone] = useState('')
const [designation, setDesignation] = useState('')
const [roles, setroles] = useState('')
const [industry, setindustry] = useState('')
const [website, setWebsite] =  useState<any>([])
const [error, seterror] = useState('')
const [rootRoleid,setrootRoleid]=useState('');
const [orgName, setOrgname] = useState<any>([])  //now its org id
//uservariables
//  active apps designEditor activeResponse, superAdmin, teams, setting
const [designEditor, setDesignEditor] = useState(true)
const [activeResponse, setActiveResponse] = useState(false)
const [superAdmin, setSuperAdmin] = useState(false)
const [teams, setTeams] = useState(true)
const [adManager, setAdManager] = useState(false)
const [setting, setSetting] = useState(true)
// active apps end

// const userorg = Cookies.get('org')

// var userrole = Cookies.get('role')
const userRole = async()=>{
  let newgp = [{label:'No Roles yet',value:'none'}];
  const { data } = await userRoles();
  let i=0;
  data.project?.map((p:any, j:any)=>{
    if(i==0){
      newgp = [{label:p.name,value:p._id}];
    } else  {
      newgp = [...newgp,{label:p.name,value:p._id}];
    }
      i++;
      if(p.name=='root'){
      setrootRoleid(p._id)
    }
  })
  setRoleslist(newgp)
}
const getallUsers = async () => {
  const userorg = Cookies.get('org')

var userrole = Cookies.get('role')
    if(userrole==rootRoleid){
      const { data } = await UserList();
      if(data?.data){
        setUsers(data?.data);
        console.log('admin set');
      }
    } else {
      const { data } = await UserListbyorg(userorg);
      if(data){
        setUsers(data);
        console.log('user set');
      }
    }

}
const getallIndustries = async () => {
  let newgp = [{label:'No Industries yet',value:''}];
  const { data } = await FetchAllIndustries();
  let i=0;
  data?.map((p:any, j:any)=>{
    if(i==0){
      newgp = [{label:p.name,value:p._id}];
   } else  {
     newgp = [...newgp,{label:p.name,value:p._id}];
   }
     i++;
  })
  setIndustriesList(newgp)
}
const createUser = async()=>{
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  if(email==''||password==''||name==''||roles==''){
    seterror('Please fill all fields')
  } else if(!pattern.test(email)) {
    seterror('Email Format is not correct!')
  } else {
    seterror('')
    const  status  = await getuserbymail(email);
    if(status.status==400){
      seterror('')
      let tenant:any;
      if(curentUserroles==rootRoleid) {

        if(orgName=='') {
          seterror('Organization not selected!')
        } else {
          tenant = orgName;
        }
      } else {
        tenant = [user.user?.tenant[0]];
      }

      if(tenant!=''){
        seterror('')

        const userdata = {
          email: email,
          password: password,
          name: name,
          phone: phone,
          secondName: secondName,
          roles:[roles],
          tenant: tenant,
          designation: designation,
          website: website,
          verified: true,
          type: "BUSINESS",
          apps:{
            designEditor:designEditor,
            activeResponse:activeResponse,
            superAdmin:superAdmin,
            teams:teams,
            setting:setting,
            adManager:adManager,
          }
        };
        const  data  = await adminCreateUser(userdata);
        if(data){
          notifications.showNotification({
            title: "Property Created Successfully!",
            message: "",autoClose: 4000,
            color: 'lime',
            loading: false,
            disallowClose: false
        });
          setCreate(false)
          getallUsers();
        } else {
          seterror('Please try again')
        }
      }

    } else {
        seterror('Email already exists')
    }
  }
}
const getallwebsite = async () => {

  let newgp = [{label:'No websites yet',value:'',orgId:''}];
  const { data } = await Providerswebsite();
  let i=0;
  data?.map((p:any, j:any)=>{
    // if(p.orgId==userorg||userrole==rootRoleid){
  // if(p.orgId==orgName){
    if(i==0){
      newgp = [{label:p.name,value:p._id,orgId:p.orgId}];
   } else  {
     newgp = [...newgp,{label:p.name,value:p._id,orgId:p.orgId}];
   }
     i++;
  // }
  })
  setWebsiteList(newgp);
}
const selectedWebsites = async (orgid:any)=> {
  setWebsite([])
  let newgp = [{label:'No websites yet',value:'',orgId:''}];
  setOrgname(orgid);
  let j=0;
  orgid.map((org:any)=>{
    let i=0;
    websiteList.map((p:any)=>{
      if(p.orgId==org){
        if(i==0 && j==0){
          newgp = [{label:p.label,value:p.value,orgId:p.orgId}];
        } else  {
          newgp = [...newgp,{label:p.label,value:p.value,orgId:p.orgId}];
        }
        i++;
      }
    })
    j++;
  })
  setSelectedWebsiteList(newgp)
}


const getuserdetails = async()=> {
  const  data  = await Userdetails();
  if(data?.data?.user?._id!=undefined){
        setcurentUserroles(data?.data.roles[0])
        setUser(data?.data)
        permisions(data?.data?.roles[0],data?.data?.user?.tenant[0])
  }
  getallUsers();
}
const [usercreate ,setUsercreate] = React.useState('')
const [userlist ,setUserlist] = React.useState('')
const [useredit ,setUseredit] = React.useState('')
const [userdelete ,setUserdelete] = React.useState('');
const permisions = async(role:any,org:any)=> {
  const { data } = await getpermisionsbymodule(role,org,'users');
  console.log(data);
  data.map((elements:any)=>{
    if(elements[2]=='users') {
      if(elements[3]=='create') {setUsercreate('create') }
      if(elements[3]=='list') {setUserlist('list') }
      if(elements[3]=='edit') {setUseredit('edit') }
      if(elements[3]=='delete') {setUserdelete('delete') }
    }
  })
}
const allOrganization=async()=>{
  let newgp = [{label:'No Roles yet',value:'none'}];
  const  data = await allOrganizations();
  if(data){
    let i=0;
    data.data?.map((p:any, j:any)=>{
    if(i==0){
      newgp = [{label:p.name,value:p._id}];
   } else  {
     newgp = [...newgp,{label:p.name,value:p._id}];
   }
     i++;
  })
  setallOrg(newgp)

  }
}
useEffect(() => {

  getallIndustries();
  getallwebsite();
  allOrganization();
  if(rootRoleid==''){
      userRole();
  } else {

  getuserdetails();
  }

}, [rootRoleid]);
  return (<>
<div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
    <div className="bg-gray-800 pt-3">
        <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 p-4 shadow text-2xl ">
            <h3 className="font-bold pl-2 mt-2">Account Users</h3>
        </div>
    </div>
<div className='flex bg-gray-200 py-2'>
  <div className='flex-1 flex'>
    <div className='relative'>
    <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2' />
    <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
    </div>
  </div>
  <div className='flex-1 text-right pr-3'>
    {/* <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500 relative pr-7 pl-2 text-xs h-9 font-bold
    hover:bg-black hover:text-white transition-all'>
       Export
        <DownloadIcon className='h-6 absolute top-1.5 right-1'/>
    </button> */}
    {/* usercreate=='create'&& */}
    {usercreate=='create'&&
    <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500  pr-7 pl-2 text-xs font-bold h-9 relative ml-2
    hover:bg-black hover:text-white transition-all' onClick={() => {setCreate(true)}}>Create User
      <UserAddIcon className='h-6 absolute top-1.5 right-1'/>
    </button>}
  </div>
  {/* userlist=='list'&& */}
</div>{userlist=='list'&&
    <div className="flex flex-wrap">
    <table className="table-auto flex-1 mx-3 bg-white rounded-xl overflow-hidden shadow-lg text-left mt-3">
      <thead className='bg-gradient-to-r from-black to-gray-700 text-white '>
        <tr>
          <th  className='py-2 w-10'>
          </th>
          <th  className='py-2 '>User</th>
          <th  className='py-2'>Email</th>
          <th  className='py-2'>Role</th>
          <th  className='py-2'>Designation</th>
          <th  className='py-2'>Industry</th>
          <th  className='py-2'>Organization</th>
          <th  className='py-2'>Last Login</th>
          <th  className='py-2 w-14'></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user,i)=>(

        <Tablerow edituser={useredit} deleteuser={userdelete} rootRoleid={rootRoleid}  onSelects={(step) => getallUsers()}  user={user} roleslist={roleslist} industriesList={industriesList} websiteList={websiteList} allOrg={allOrg} curentUserroles={curentUserroles}></Tablerow>
        )
        )}
      </tbody>
    </table>
    </div>}
</div>



<Transition.Root show={create} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto"  onClose={()=>{setCreate(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                     Create User.
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      <button onClick={() => setCreate(false)} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
                <div className='px-4 '>
                  <div className="flex">
                  <div className='pt-2 flex-1 mr-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>First Name</div>
                    <input type='text' name="name" onChange={(ev)=>setname(ev.target.value)}
                    className='w-full text-sm border rounded-lg' placeholder='First name' required></input>
                  </div>
                  <div className='pt-2 flex-1'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Second Name</div>
                    <input type='text' name="name" onChange={(ev)=>setsecondName(ev.target.value)}
                    className='w-full text-sm border rounded-lg' placeholder='Second name' required></input>
                  </div>
                  </div>
                  <div className="flex">
                    <div className='pt-2 flex-1 mr-2'>
                      <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Email Address</div>
                      <input type='email' name="email" onChange={(ev)=>setemail(ev.target.value)}
                      className='w-full text-sm border rounded-lg' placeholder='Email address of the user' required></input>
                    </div>
                    <div className='pt-2 flex-1'>
                      <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Phone number</div>
                      <input type='number' name="phone" onChange={(ev)=>setPhone(ev.target.value)}
                      className='w-full text-sm border rounded-lg' placeholder='Phone ' required ></input>
                    </div>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Password</div>
                    <input type='password' name="password" onChange={(ev)=>setpassword(ev.target.value)}
                    className='w-full text-sm border rounded-lg' placeholder='Password' required></input>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Designation</div>
                    <input type='text' name="designation" onChange={(ev)=>setDesignation(ev.target.value)}
                    className='w-full text-sm border rounded-lg' placeholder='Designation of user' required></input>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Role</div>
                    <Select className='w-full text-sm border rounded-lg'
                      placeholder="Select role" searchable data={roleslist} onChange={(e:string) => setroles(e)} />
                  </div>
                  {curentUserroles==rootRoleid?<>
                  <div className="">
                    <div className='pt-2 flex-1 '>
                      <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Organization</div>


                        <MultiSelect  className='w-full text-sm border rounded-lg'
                          data={allOrg}
                          // searchable
                          nothingFound="Nothing found"
                          placeholder="Select Organization"
                          onChange={(e) => selectedWebsites(e)}
                          value={orgName}
                        />
                      {/* <input type='text' name="name"  onChange={(ev)=>setOrgname(ev.target.value)}
                      className='w-full text-sm border rounded-lg' placeholder='Organization Name' required></input> */}
                    </div>
                    <div className='pt-2 flex-1'>
                      <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Industry</div>
                      <Select className='w-full text-sm border rounded-lg'
                        placeholder="Select Industry" searchable data={industriesList} onChange={(e:string) => setindustry(e)} />
                    </div>
                  </div>
                  <div className="">
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Apps </div>
                    <div className="flex border rounded p-1">
                      <div className="flex-1 text-sm">
                        <span>Active Response</span>
                        <Checkbox checked={activeResponse}  onChange={() => setActiveResponse(!activeResponse)} />
                      </div>
                      <div className="flex-1 text-sm">
                        <span>Design Editor</span>
                        <Checkbox checked={designEditor}   onChange={() => setDesignEditor(!designEditor)} />
                      </div>
                      <div className="flex-1 text-sm">
                        <span>Ad Manager</span>
                        <Checkbox checked={adManager}   onChange={() => setAdManager(!adManager)} />
                      </div>
                      <div className="flex-1 text-sm">
                        <span>Teams</span>
                        <Checkbox checked={teams}   onChange={() => setTeams(!teams)} />
                      </div>
                    </div>
                  </div>
                  </>:''}
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Website</div>
                      <MultiSelect
                          data={selectedwebsiteList}
                          value={website}
                          nothingFound="Nothing found"
                          placeholder="Select Website"
                          onChange={(e) => setWebsite(e)}
                        />
                  </div>
                  {error!=''? <div className='pt-2 text-red-400'>{error}</div>:''}
                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">

                  <AdvButton
                    color="green"
                    onClick={() =>createUser()}>
                    Create
                  </AdvButton>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
 </> );
};

export default Users;
