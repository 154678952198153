import { ApiResult, Campaign } from '@adv/interface';
import { Api } from '@adv/utils/api';
import { stringify } from 'query-string';
import Cookies from 'js-cookie';
var token= Cookies.get('token');
const config = {
  headers: { Authorization: `Bearer ${token}` }
};

const url = `schemas/getRegistries/`;



export const FetchAllregistries = async () => {
  try {
    const res = await Api.get(`/schemas/getIndustryRegistries`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const FetchAllevents = async () => {
  try {
    const res = await Api.get(`/eventbridge/getEventBuses/20`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const EventsByIndustry = async (industryId: string) => {
  try {
    const res = await Api.get(`/eventbridge/getIndustryEvents/${industryId}`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const createIndustryRegistry = async (data: any) => {
  try {
    const res = await Api.post(`schemas/createIndustryRegistry`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const CreateEvents = async (data: any) => {
  try {
    const res = await Api.post(`eventbridge/createIndustryEvent`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const DeleteEvent = async (id: string) => {
  try {
    const res = await Api.delete(`eventbridge/deleteIndustryEvent/${id}`,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const DeleteIndustryRegistry = async (industryID: string,registryname:string) => {
  try {
    const res = await Api.delete(`schemas/deleteIndustryRegistryFromDB/${industryID}/${registryname}`,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
