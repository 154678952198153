
import React, { Fragment, useEffect, useState } from 'react';

import {
  MenuIcon,
  HomeIcon,
  CubeTransparentIcon,
  BellIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  GlobeIcon,
  UserIcon,
} from '@heroicons/react/solid';

const Dashboard = () => {
 
  return (
<div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
    <div className="bg-gray-800 pt-3">
        <div className=" bg-gradient-to-r from-yellow-300 to-yellow-500 p-4 shadow text-2xl ">
            <h3 className="font-bold pl-2 mt-2">Welcome Super Admin ! </h3>
        </div>
    </div>

    <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 xl:w-1/3 p-6">
            <div className='flex rounded-2xl overflow-hidden shadow-md'>
              <div className='w-44 bg-gradient-to-r from-yellow-300 to-yellow-500 py-2 pl-4 relative'>
                <div className='text-base mt-3 font-bold'>Industries</div>
                <div className='text-3xl flex my-4'>
                <CubeTransparentIcon className='h-8 mr-2 mt-1' />
                  256</div>
                  <button className="bg-black text-white text-xs px-4 py-2 rounded-full relative -right-16 mb-2 shadow-lg mt-3 ml-2">
                    View all
                    </button>
              </div>
              <div className='w-full bg-white py-2 pl-4'>
              <div className='text-base mt-3 text-right mr-4'>12-10-2021</div>
              <div >New Details added </div>
              <div className='flex'>
                <select className='border-yellow-300 w-4/6 rounded-l  text-xs'>
                  <option>Today</option>
                  <option>This Week</option>
                </select>
                <button className='bg-yellow-300 rounded-r px-1 hover:bg-yellow-400'>
                  <ChevronRightIcon className='h-7'/>
                </button>
              </div>
              
              </div>
            </div>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 p-6">
            <div className='flex rounded-2xl overflow-hidden shadow-md'>
              <div className='w-44 bg-gradient-to-r from-yellow-300 to-yellow-500 py-2 pl-4 relative'>
                <div className='text-base mt-3 font-bold'>Subscription</div>
                <div className='text-3xl flex my-4'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 mr-2 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z" />
                    </svg>
                  256</div>
                  <button className="bg-black text-white text-xs px-4 py-2 rounded-full relative -right-16 mb-2 shadow-lg mt-3 ml-2">
                    View all
                    </button>
              </div>
              <div className='w-full bg-white py-2 pl-4'>
              <div className='text-base mt-3 text-right mr-4'>12-10-2021</div>
              <div >New Subscriptions </div>
              <div className='flex'>
                <select className='border-yellow-300 w-4/6 rounded-l  text-xs'>
                  <option>Today</option>
                  <option>This Week</option>
                </select>
                <button className='bg-yellow-300 rounded-r px-1 hover:bg-yellow-400'>
                  <ChevronRightIcon className='h-7'/>
                </button>
              </div>
              
              </div>
            </div>
        </div>
    </div>
</div>
  );
};

export default Dashboard;
