
import React, { Fragment, useEffect, useState } from 'react';
import { router } from '../../routes';
import {  SearchIcon,} from '@heroicons/react/solid';
import { Providerswebsite , FetchAllindustryProviders } from '@adv/sdk/Industry';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { Link, useRouteParams } from 'react-typesafe-routes';
import { MultiSelect,Select } from '@mantine/core';
import WebsiteCard from './websiteCard';
import { useUser } from '../../components/organisms/Layout/Layout';
import Cookies from 'js-cookie';
import { userRoles} from '@adv/sdk/user';

export default function WebsitesAll() {
  // const{ userData } = useUser();
  const org =Cookies.get('org');
  const role =Cookies.get('role');
  const options = useRouteParams(router.providers);
  const [website, setwebsite] = useState<any[]>([])
  const [Providers, setProviders] = useState<any[]>([])
  const [Providersfilter, setProvidersfilter] = useState('')
  const [search, setsearch] = useState('')

  const getallwebsite = async () => {
    const { data } = await Providerswebsite();
    setwebsite(data);
    console.log(data);
  }
  const getallProviders = async () => {
    let newgp = [{label:'No Providers yet',value:'none'}];
    const { data } = await FetchAllindustryProviders();
    let i=0;
    data?.map((Provider:any, j:any) => {
      // console.log(group.name)
      if(i==0){
         newgp = [{label:Provider.name,value:Provider._id}];
      } else  {
        newgp = [...newgp,{label:Provider.name,value:Provider._id}];
      }
        i++;
    })
    setProviders(newgp);
    console.log(newgp);
  }
  function limit(string = '', limit = 0) {
    return string.substring(0, limit)
  }
  const [rootRoleid,setrootRoleid]=useState('');
const userRole = async()=>{
  const { data } = await userRoles();
  data.project?.map((p:any, j:any)=>{
     if(p.name=='root'){
      setrootRoleid(p._id)
    }
  })
}
useEffect(() => {
  userRole();
  getallProviders();
  getallwebsite();
}, []);
return (
  <div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
      <div className="bg-gray-800 pt-3">
          <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 p-4 shadow text-2xl ">
              <h3 className="font-bold pl-2 mt-2">Websites </h3>
          </div>
      </div>
    <div className='flex bg-gray-200 py-2'>
      <div className='flex-1 flex'>
      {Providers[0]?.value=='none'?'':
              <Select className='ml-2 border border-gray-200 rounded-md text-xs w-44'
              placeholder="Select Provider" searchable data={Providers} value={Providersfilter} onChange={(ev)=>setProvidersfilter(ev!)} />
            }
        <div className='relative'>
          <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2' onChange={(ev) => setsearch(ev.target.value)}/>
          <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
        </div>
      </div>
      <div className='flex-1  text-right pr-3'>
          {/* <input type='date' className='ml-2 border border-gray-200 rounded-md text-xs'></input> */}
        </div>
    </div>
    <div className="flex flex-wrap overflow-y-scroll">
      {website?.length == 0 ?
        'Loading...'
        :<>
      {website.map((web,i)=>(
       web.orgId==org||role==rootRoleid?
        Providersfilter==web.provider||Providersfilter==''?
        (web.name?.toLowerCase().includes(search?.toLowerCase())||web.creator?.toLowerCase().includes(search?.toLowerCase()))?<>
        <WebsiteCard web={web} Providers={Providers} onupdate={(step) => getallwebsite()}/>
        </>:''
          :''
          :''
      ))}</>}
    </div>
  </div>
  );
};

