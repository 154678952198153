import { ApiResult, Segments, SegmentsQuery } from '@adv/interface';
import { Api } from '@adv/utils/api';
import Cookies from 'js-cookie';
import { stringify } from 'querystring';
var token= Cookies.get('token');
const config = {
  headers: { Authorization: `Bearer ${token}` }
};
const url = `industry`;
export const FetchAllIndustries = async () => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
};
  try {
    const res = await Api.get(url,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const CreateIndustry = async (data: any) => {
  try {
    const res = await Api.post(`${url}`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const CreateProviders = async (data: any) => {
  try {
    const res = await Api.post(`industry-providers`, data,config);
    return {
      status: 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const UpdateIndustry = async (id: string, data: any) => {
  try {
    const res = await Api.patch(`${url}/${id}`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const DeleteIndustry = async (id: string) => {
  try {
    const res = await Api.delete(`${url}/${id}`,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const FetchAllindustryProviders = async () => {

  try {
    const res = await Api.get('industry-providers',config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const industryProvidersById = async (
  id: string
) => {

  try {
    const res = await Api.get(`industry-providers/${id}`,config);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};
export const Providerswebsite = async () => {
  try {
    const res = await Api.get('website',config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
// export const FetchAlltraits = async () => {
//   try {
//     const res = await Api.get('industry-traits');
//     return {
//       status: res.status || 200,
//       data: res.data || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
// export const GetSegmentsById = async (
//   id: string
// ): Promise<ApiResult<Segments>> => {
//   try {
//     const res = await Api.get(`${url}/${id}`);
//     return {
//       status: +res.status || 200,
//       data: res.data || null,
//     };
//   } catch (error) {
//     return { status: 400, error, data: null };
//   }
// };
// export const SegmentUserBywebsite = async ( 
//   segmentIds: string,
//   websiteId: string
// ) => {
//   try {
//     const res = await Api.get(`${url}/users?segmentIds=${segmentIds}&websiteId=${websiteId}`);
//     return {
//       status: +res.status || 200,
//       data: res.data || null,
//     };
//   } catch (error) {
//     return { status: 400, error, data: null };
//   }
// };
// export const industry_traits_website = async (
//   id: string
// ) => {
//   try {
//     const res = await Api.get(`industry-traits/website/${id}`);
//     return {
//       status: +res.status || 200,
//       data: res.data || null,
//     };
//   } catch (error) {
//     return { status: 400, error, data: null };
//   }
// };
// export const CreateNewSegments = async (data: Partial<Segments>) => {
//   try {
//     const res = await Api.post(`${url}/create`, data);
//     return {
//       status: 200,
//       data: res || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };

// export const UpdateSegments = async (id: string, data: Partial<Segments>) => {
//   try {
//     const res = await Api.patch(`${url}/${id}`, data);
//     return {
//       status: 200,
//       data: res || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
// export const DeleteSegments = async (id: string) => {
//   try {
//     const res = await Api.delete(`${url}/${id}`);
//     return {
//       status: 200,
//       data: res || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };

// export const getSegmentUsers = async (query: any) => {
//   console.log(query, 'query');
//   try {
//     const res = await Api.get(`${url}/users?${stringify(query)}`);
//     return {
//       status: 200,
//       data: res || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
