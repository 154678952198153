import axios from 'axios';
import { sessionGet } from './storage';
import { environment } from 'apps/adv-campaign-monitor/src/environments/environment';
const URL =
  environment.apiUrl ||
  process.env.NX_API_URL ||
  process.env.API_URL ||
  process.env.REACT_APP_API_URL;

export const GetHeader = () => {
  let uid = '';
  const saved = sessionGet('uid');
  if (saved) uid = saved;
  return {
    'x-uid': uid,
  };
};

export const Api = axios.create({
  baseURL: URL + '/api',
  headers: GetHeader(),
});
