import React, { FunctionComponent, useEffect, useState } from 'react';
import { LockClosedIcon,GlobeAltIcon } from '@heroicons/react/solid';
import { Link, useRouteOptions, useRouteParams } from 'react-typesafe-routes';
import { router } from '../../../routes';
import { createOrganization,Userdetails,UpdateUser } from '@adv/sdk/user';
import Cookies from 'js-cookie';
const Dropbutton: FunctionComponent = () => {
  const [terms,setTerms] =useState(false);
  const [userid,setUserid] =useState('');
  const [tenant,settenant] =useState(''); //for the time being
  const [groupdata,setgroupdata] = useState<any[]>([])
  const [roleslist, setRoleslist] = useState<any[]>([])
  const [steps,setsteps] =useState(1);

  const [Error,setError]=useState('');

  const [email,setEmail]=useState('');
  const [roles,setRoles]=useState('');
  const [grouptype,setgrouptype]=useState('');
  const [avatar,setavatar]=useState<any>(process.env.NX_DESIGN_STUDIO_URL+'static/media/avatar.642eb204.png');
  const [name,setname]=useState('');
  const [secondName,setsecondName]=useState('');
  const [phoneNo,setphoneNo]=useState('');
  const [orgName,setOrgname]=useState('');
  const [organizationWebsite,setOrganizationWebsite]=useState('');
  const [industry,setIndustry]=useState('');
  const [country,setCountry]=useState('');
  const [city,setCity]=useState('');
  const [zip,setZip]=useState('');
  const [address,setAddress]=useState('');
  const [timezone,setTimezone]=useState('');
  const [activeApps,setActiveApps]=useState<any>({})
  const urlvalidation = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  function logOut() {
    try {
      Cookies.remove('token');
      Cookies.remove('token',{ domain: 'advrtaiz.com' });
    window.location.href = `${process.env.NX_APPS_URL}`;
    } catch (error) {
      console.log(error);
    }

    // return false;
  }
  const getuserdetails = async()=> {
    const  data  = await Userdetails();
    if(data?.data?.user?._id!=undefined){
          console.log(data?.data)
      setUserid(data?.data?.user?._id)
      setEmail(data?.data?.user?.email)
      setname(data?.data?.user?.name)
      if(data?.data?.user?.avatar?.Key!=undefined){
        setavatar(process.env.NX_MEDIA_URL+data?.data?.user?.avatar?.Key)
      }

      setsecondName(data?.data?.user?.secondName)
      setRoles(data?.data?.roles[0])
      settenant(data?.data?.user?.tenant[0])
      if(data?.data?.user?.apps!=undefined){
        setActiveApps(data?.data?.user?.apps)
      }

    }
  }
  useEffect(() => {
    getuserdetails();
  }, []);
  return (
   <div className=''>
          <div className="tablebox drop-button  focus:outline-none relative z-50 ">
            <div className='flex pb-1'>
                {avatar!=null?
                <img src={avatar} className="h-10 w-10 mr-2 rounded-full"></img>
                :
              <div className='rounded-full bg-yellow-500 text-white font-bold w-8 h-8 text-center  capitalize text-6xl overflow-hidden relative mr-2 top-1 p-1'>
                {/* <span className='relative bottom-2'>{name.charAt(1)}</span> */}
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
</svg>

                </div>}
              <div className='pt-1'>
                <span className="pr-2 capitalize text-lg font-semibold"><i className="em em-robot_face"></i> {name}  </span>

                {/* <div className='text-xs mr-5'>{email}</div> */}
              </div>

            </div>

            <div className="tablecontent dropdownlist absolute shadow-lg w-72 right-0 p-2 border rounded-lg bg-white  z-50">
              {/* <div className='my-1 '>{email}</div> */}
              <div className='transition-all'><span className='text-sm'>Your Current plan: </span> Free</div>
              {/* <div className=''><span className='text-sm'>Next invoice: </span> Sun, Feb 6, 2022</div>
              <div>
                <div className='rounded overflow-hidden w-full bg-yellow-200 mt-5 transition-all'>
                  <div className='w-10 h-2 bg-yellow-500'></div>
                </div>
                <span className='text-xs text-gray-600'>10 of 500 emails sent this cycle.</span>
                <div className='text-sm text-gray-600'>Daily safe sending : 0/250 <a className='text-yellow-700'>Learn more</a></div>
              </div>
              <div>
                <div className='rounded overflow-hidden w-full bg-yellow-200 mt-5 transition-all'>
                  <div className='w-2 h-2 bg-yellow-500'></div>
                </div>
                <span className='text-xs text-gray-600'>$0.10 of $2.00 Rules Engine credits used this cycle.</span>
              </div> */}
              <div className='text-sm text-gray-500 my-4'>Account ID: {userid}</div>
              {/* <div>
              <a href="#" className='text-center p-2 my-2 block border-2 border-gray-600 hover:bg-gray-800 hover:text-white transition-all'>Subscribe Rules Engine credits</a>
              <span className='text-xs text-gray-600'>Want to do more with Rules Engine</span>
              <a href="#" className='text-center p-2 my-2 block border-2 border-yellow-500 hover:bg-yellow-500 hover:text-white transition-all'>Upgrade your plan</a>
              </div> */}
              <div className="border-b border-gray-800 mb-2"></div>
              <a className='block hover:bg-gray-300 px-4 py-1 -mx-2' href={process.env.NX_APPS_URL+"/settings"}>Account management</a>
              {activeApps.activeResponse==true?<>
              <a className='block hover:bg-gray-300 px-4 py-1 -mx-2' href={process.env.NX_ACTIVE_RESPONSE+"website/create"}>Connect your Website</a>
              </>:''}
              {/* <a className='block hover:bg-gray-300 px-4 py-1 -mx-2' href="">Help</a> */}
              <div className="border-b border-gray-800 mt-2"></div>
              <button onClick={()=>logOut()}
                className="py-2 hover:bg-gray-800  text-sm no-underline hover:no-underline block px-3 w-full hover:text-white transition-all rounded-b-lg">
                <i className="fas fa-sign-out-alt fa-fw"></i> Log Out
              </button>
            </div>
          </div>
        </div>

  );
};

export default Dropbutton;
