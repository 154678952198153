
import React, { Fragment, useEffect, useState } from 'react';

import {
  MenuIcon,
  ChevronLeftIcon,
  DownloadIcon,
  UserAddIcon,
  BellIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  GlobeIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { router } from '../../routes';
import { Link, useRouteParams } from 'react-typesafe-routes';
import { Tabs,Tab } from '@mantine/core';
import Properties from './properties';
import Groups from './groups';
import { industryProvidersById } from '@adv/sdk/Industry';
import { FetchAllPropertiesID } from '@adv/sdk/group';
const WebsiteMetadata = () => {
  const options = useRouteParams(router.WebsiteMetadata);
  const [activeTab, setActiveTab] = useState(0);
  const [industry, setIndustry] = useState('')
  const [industryProperties,setindustryProperties] = useState<any | undefined>([])
  const industryProvider = async()=>{
    const { data } = await industryProvidersById(options.provider);
    console.log(data?.industry)
    setIndustry(data?.industry);
  }
  const fetchpropertiesbyindustry = async () => {
    let newprop = [{label:'No Groups yet',value:'none'}]; debugger
    const { data } = await FetchAllPropertiesID(industry);
    console.log(data);
    let i=0;
    data?.map((group:any, j:any) => {
      if(i==0){
        newprop = [{label:group.name,value:group._id}];
      } else  {
        newprop = [...newprop,{label:group.name,value:group.name}];
      }
        i++;
    })
    setindustryProperties(newprop);
    // setMyTime(new Date());
  }
  useEffect(() => {
    industryProvider();
    {industry!=''?    fetchpropertiesbyindustry() :''}
  }, [industry]);
  return (
    <div className="main-content flex-1  pb-24 md:pb-5 mt-12">
      <div className="bg-gray-800 pt-3">
          <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 py-4 shadow text-2xl flex">
            <button onClick={() => history.back()} className="group cursor-pointer ">
              <ChevronLeftIcon className="text-black-300 group-hover:text-gray-600 h-7 w-7 mt-2 ml-4" />
            </button>
            <h3 className="font-bold pl-2 mt-2">{options.name} MetaData</h3>
          </div>
      </div>
        <Tabs active={activeTab} onTabChange={setActiveTab}>
          <Tab label="Properties" className='font-bold'>
            <Properties industryId={industry} websitename={options.name} websiteiD={options.id} industryProperties={industryProperties}/>
          </Tab>
          <Tab label="Groups"  className='font-bold'>
            <Groups industryId={industry} websitename={options.name}/>
          </Tab>
          {/* <Tab label="Archived Properties"  className='font-bold'>
            <h2 className='text-center text-4xl mt-10 font-bold text-gray-200 '>No data </h2>
          </Tab> */}
        </Tabs>
    </div>
  );
};

export default WebsiteMetadata;
