import React, { useEffect } from 'react';
import Layout from '../components/organisms/Layout';
import { RouterSwitch } from 'react-typesafe-routes';
import { router } from '../routes';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NotificationsProvider } from '@mantine/notifications';

const queryClient = new QueryClient();
function App() {
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   dispatch(AppInitialize());
  // }, []);
  return (
    <MantineProvider theme={{ primaryColor: 'lime' }}>
      <NotificationsProvider>
        <QueryClientProvider client={queryClient}>
          <Layout>
            <RouterSwitch router={router} />
          </Layout>
        </QueryClientProvider>
      </NotificationsProvider>
    </MantineProvider>
  );
}

export default App;
