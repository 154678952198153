import React, { Fragment, useEffect, useState } from 'react';
import { router } from '../../routes';
import { Link, useRouteParams } from 'react-typesafe-routes';
import { FetchAllindustryProviders ,CreateProviders} from '@adv/sdk/Industry';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import {  SearchIcon,ChevronLeftIcon,PlusIcon} from '@heroicons/react/solid';
import { Dialog, Transition } from '@headlessui/react'
import { MultiSelect,Select } from '@mantine/core';
import { AdvButton } from '@adv/ui';
import {WorkFlowList,WorkFlowDetails,executeWorkflow} from '@adv/sdk/workFlow';
import { useNotifications } from "@mantine/notifications";
import { getEventSchemas,getSchemasByIndustry,getSchema } from '@adv/sdk/schema';
import { FetchAllevents,EventsByIndustry,CreateEvents } from '@adv/sdk/registry';
import { Console } from 'console';
const Providers = () => {
  const options = useRouteParams(router.providers);
  const [workflows, setWorkflows] = useState<any[]>([])
  const [industries, setIndustries] = useState<any[]>([])
  const [rawschemas, setRawschemas] = useState<any[]>([])
  const [EVENTschemas, setEVENTschemas] = useState<any[]>([])
  const [schemas, setSchemas] = useState<any[]>([])
  const [registries, setRegistries] = useState<any[]>([])

  const [create, setcreate] = useState(false)
  const [execshow, setExecshow] = useState(false)

  const notifications = useNotifications();
  const [content,setContent] = useState('')
  const [stateMachineArn,setStateMachineArn] = useState('')
  const [providerName,setProviderName] = useState('')
  const cyrb53 = function (str:any, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return (h2 >>> 0).toString(16).padStart(8, '0') + (h1 >>> 0).toString(16).padStart(8, '0');
  };

const getallIndustries = async () => {
  const { data } = await FetchAllindustryProviders();
  setIndustries(data);
}
const geteventschemas = async () => {
  const { data } = await getEventSchemas(options.id);
  setEVENTschemas(data);
  getAllschemas();
}
const getAllschemas = async () => {
  const { data } = await getSchemasByIndustry(options.id);
  setRawschemas(data);
}
const schemaGet = async(eventName:any) => {
  var regname = '';
  var schemaname = '';
  EVENTschemas?.map((p:any, j:any)=>{
    if(p.eventName==eventName){
      schemaname = p.schemaName;
    }
   })
   rawschemas?.map((SCHE:any, j:any)=>{
    if(SCHE.SchemaName==schemaname){
      regname = SCHE.regName;
      schemaname =SCHE.SchemaName;
    }
   })
 const { data } = await getSchema(regname,schemaname);
  setContent(data.Content);
  // setcreate(true);
}
const WorkFlows = async () => {
  let newgp = [{label:'No Workflows yet',value:''}];
  const { data } = await WorkFlowList(options.id);
  console.log(data)
  let i=0;
  data?.map((p:any, j:any)=>{
    if(i==0){
      newgp = [{label:p.workflowName,value:p.stateMachineArn}];
   } else  {
     newgp = [...newgp,{label:p.workflowName,value:p.stateMachineArn}];
   }
     i++;
  })
  setWorkflows(newgp)
}
const getAllEvents = async () => {
  let newgp = [{label:'No Industries yet',value:''}];
  const { data } = await EventsByIndustry(options.id);
  let i=0;
  data?.map((p:any, j:any)=>{
    if(i==0){
      newgp = [{label:p.eventName,value:p.eventName}];
   } else  {
     newgp = [...newgp,{label:p.eventName,value:p.eventName}];
   }
     i++;
  })
  setRegistries(newgp);
}




const executeevent = async () => {
  var sname = 'workflow';
  var d = new Date();
  var n = d.getTime();
  var hashedSname = cyrb53(sname + n, 1);
  if(content!=''||stateMachineArn!=''){
    let uploaddata = {
      stateMachineArn:stateMachineArn,
      input: content,
      name:hashedSname
    }
    try {
      const { data } = await executeWorkflow(uploaddata);
      if(data.executionArn!=undefined){
        notifications.showNotification({
          title: "Workflow executed Successfully Successfully!",
          message: "",autoClose: 2000,
          color: 'green',
          loading: false,
          disallowClose: false
        });
      }
    } catch {
      notifications.showNotification({
        title: "Execution error!",
        message: "",autoClose: 2000,
        color: 'red',
        loading: false,
        disallowClose: false
      });
    }

  } else {
    notifications.showNotification({
      title: "Enter Valid details!",
      message: "",autoClose: 2000,
      color: 'red',
      loading: false,
      disallowClose: false
    });
  }

}
const createNewProvider = async () => {

  let uploaDtata = {
    name: providerName,
    industry:options.id
  }
    const { data } = await CreateProviders(uploaDtata);
    if(data.status==true) {
      getallIndustries()
     } else {
      console.log('error')
     }
}
useEffect(() => {
  getallIndustries();
  WorkFlows()
  geteventschemas()
  getAllEvents()
}, []);
  return (
    <div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
        <div className="bg-gray-800 pt-3">
            <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 py-4 pr-4 shadow text-2xl flex">
              <button onClick={() => history.back()} className="group cursor-pointer ">
                <ChevronLeftIcon className="text-black-300 group-hover:text-gray-600 h-7 w-7 mt-2 ml-4" />
              </button >
                <h3 className="font-bold pl-2 mt-2">{options.name} Providers</h3>
                <div className='flex-1 text-right'>
                <button  onClick={() => {setExecshow(true)}}
                className='relative top-2 mr-2 text-lg bg-black text-white rounded-md px-2 py-1 hover:bg-gray-800 transition-all'>
                  Execute Workflow
                </button>
                <Link  to={router.Schemas({industryId:options.id})}
                className='relative top-2 mr-2 text-lg bg-black text-white rounded-md px-2 py-1 hover:bg-gray-800 transition-all'>
                  Event Schema
                  </Link>
                <Link  to={router.IndustryEventsById({industryId:options.id})}
                className='relative top-2 mr-2 text-lg bg-black text-white rounded-md px-2 py-1 hover:bg-gray-800 transition-all'>
                  Events
                  </Link>
                {/* <Link  to={router.industrymetadata({ id: options.id, name: options.name})} */}
                <Link  to={router.industrymetadata()}
                className='relative top-2 text-lg bg-black text-white rounded-md px-2 py-1 hover:bg-gray-800 transition-all'
                  >
                  MetaData
                  </Link>
                </div>
            </div>
        </div>
    <div className='flex bg-gray-200 py-2'>
      <div className='flex-1 flex'>
      <div className='relative'>
        <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2' />
        <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
      </div>
      </div>
      <div className='flex-1  text-right pr-3'>
        <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500  pr-7 pl-2 text-xs font-bold h-9 relative ml-2
          hover:bg-black hover:text-white transition-all' onClick={() => {setcreate(true)}}>Create Provider
              <PlusIcon className='h-6 absolute top-1.5 right-1'/>
          </button>
      </div>
    </div>
        <div className="flex flex-wrap">
        {industries?.length == 0 ?
      'Loading...'
      :<>

        {industries.map((industry,i)=>(<>
          {options.id==industry.industry?
            <div className="w-full md:w-1/2 xl:w-1/4 p-6 capitalize">
                <div className='flex rounded-2xl overflow-hidden shadow-md'>
                  <div className='flex-1 bg-gradient-to-r from-black to-gray-700 py-2 pl-4 relative text-white relative'>
                    {industry.status==true?
                      <span className="bg-green-500 px-1 rounded-xl absolute right-3 text-xs top-4">Active</span>
                    :
                      <span className="bg-red-500 px-1 rounded-xl absolute right-3 text-xs top-4">Inactive</span>
                    }
                    <div className='text-base mt-3  flex bg-green'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-11 w-11" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                      </svg>
                      <div className='text-md flex-1  mr-2 text-left pl-3'>{industry.name}
                        <div className='text-xs '>{format(new Date(industry.createdAt), 'dd-MM-yyyy')}</div>
                      </div>
                    </div>
                    <div className='text-xs flex my-1 mt-2'>

                    </div>
                    <div className='text-lg flex my-1'>
                      <div className='w-1/3  mt-1'>Sites</div><div className='w-3/4 text-2xl'>120</div>
                    </div>
                    <Link  to={router.websites({ id: industry._id, name: industry.name})}>
                      <button className="bg-white text-black text-xs px-4 py-1 rounded-lg relative right-3 top-1  mb-2 shadow-lg mt-3 ml-2">
                        Details
                      </button>
                    </Link>

                  </div>
                  <div className=' bg-white py-2 px-1 flex flex-col'>
                    <button className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </button>
                    <button className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </div>
                </div>
            </div>:''}
        </>))}
        </>}

        <Transition.Root show={execshow} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto"  onClose={()=>{setExecshow(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                      Execute Workflow
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      <button onClick={() => {setExecshow(false);}} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
                <div className='px-4 '>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>
                      Workflow</div>
                    <Select className='w-full text-sm border rounded-lg'
                      placeholder="Select Workflow" searchable data={workflows} onChange={(e:string) => setStateMachineArn(e)} />
                      <div className='text-sm'>{stateMachineArn}</div>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>
                      Event</div>
                    <Select className='w-full text-sm border rounded-lg'
                      placeholder="Select Event" searchable data={registries} onChange={(e) => schemaGet(e)} />
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Params to execute the Workflow.</div>
                    <textarea name="name"   className='w-full text-sm border rounded-lg h-60' placeholder='Schema content'
                    onChange={(ev) => setContent(ev.target.value)} value={content}>

                    </textarea>
                  </div>

                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">
                  <AdvButton type="submit"
                    color="green"
                     onClick={() => executeevent()}
                   // onClick={onupdate.bind(null, 'hii')}
                    >
                    Execute
                  </AdvButton>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
    <Transition.Root show={create} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto"  onClose={()=>{setcreate(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                      Create New Provider
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      <button onClick={() => {setcreate(false);}} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
                <div className='px-4 '>
                <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Provider Name</div>
                    <input type='text' name="name"  onChange={(ev)=>setProviderName(ev.target.value)} className='w-full text-sm border rounded-lg' placeholder='Name of the Provider' required></input>
                  </div>

                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">
                  <AdvButton type="submit"
                    color="green"
                     onClick={() => createNewProvider()}
                   // onClick={onupdate.bind(null, 'hii')}
                    >
                    Execute
                  </AdvButton>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
        </div>
    </div>
  );
};

export default Providers;
