
import React, { Fragment, useEffect, useState,FunctionComponent,useRef } from 'react';
import { router } from '../../routes';
import { Link, useRouteActive } from 'react-typesafe-routes';
import { AdvButton } from '@adv/ui';
import { Dialog, Transition } from '@headlessui/react'
import { useNotifications } from "@mantine/notifications";
import {format,parseISO} from 'date-fns';
import { MultiSelect,Select } from '@mantine/core';
import { permisionsUpdate } from '@adv/sdk/permissions';
import TablesubRow from './tablesubRow';
import CountriesList from '@adv/sdk/countriesList';
import TimeZone from '@adv/sdk/timeZone';
interface CustomProps {
    permisions: any;
    onSelects:(newind: string) => void;
  }
const Tablerow: FunctionComponent<CustomProps> = ({
    onSelects,permisions
  }: CustomProps) => {
    const notifications = useNotifications();
    const [togle, settogle] = useState(false) 
    const [permissionLabel, setPermissionLabel] = useState(permisions.permissionLabel) 
    const [admin, setAdmin] = React.useState(permisions.userPermission.admin);
    const [user, setUser] = React.useState(permisions.userPermission.user);
    const [guest, setGuest] = React.useState(permisions.userPermission.guest);
    const [submitbtn, setSubmitbtn] = React.useState(false);
    const updatePermision = async()=> {
      var module = permisions.module;
      if(module=='campaign Menu'||module=='designeditor Menu'||module=='ad Manager Menu') {
        module='sideMenu';
      }
       let  permisionData = {
        orgId:permisions.orgId,
        module: module,
        userPermission: {
          admin:admin,
          user:user,
          guest:guest
        },
        permissionAction: permisions.permissionAction,
        permissionLabel: permisions.permissionLabel
      }
      console.log(permisionData)
      try {
        const { data } = await permisionsUpdate(permisionData);
        console.log(data)
        notifications.showNotification({
          title: "Permision Updated Successfully!",
          message: "",autoClose: 2000,
          color: 'green',
          loading: false,
          disallowClose: false
        });
      }catch {
        notifications.showNotification({
          title: "Execution error!",
          message: "",autoClose: 2000,
          color: 'red',
          loading: false,
          disallowClose: false
        });
      }

      setSubmitbtn(false)
     }
  return (<>
         <div className='w-full grid grid-cols-5 p-3 '>
           
            
            <div className="">{permisions.permissionLabel} 
           
            </div>
            <div className="text-center"><input type="checkbox" id="vehicle1" name="vehicle1" onChange={() => {setAdmin(!admin);setSubmitbtn(true)}} defaultChecked={admin}/></div>
            <div className="text-center"><input type="checkbox" id="vehicle1" name="vehicle1" onChange={() => {setUser(!user);setSubmitbtn(true)}} defaultChecked={user}/></div>
            <div className="text-center"><input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" onChange={() => {setGuest(!guest);setSubmitbtn(true)}} defaultChecked={guest}/></div>
            <div className=' '>
              {submitbtn==true?
                <button type="submit"  className="bg-primary-600 px-1 font-semibold rounded-lg" onClick={()=>updatePermision()}>Update</button>
              :''}
              {permisions.sub.length>1? 
           <button className='bg-black float-right text-white text-xs p-1 rounded-lg hover:bg-gray-600'
           onClick={() => settogle(!togle)}>
            {togle==false? 'show more':'hide'}
           </button>
           :''}
              </div>
          </div>
          {permisions.sub.length>1 && togle==true ?
          permisions.sub.map((s:any,j:any)=>(
            j!=0?
            <TablesubRow permisions={s}  onSelects={(step) =>  onSelects('hii')} ></TablesubRow>
            :''
          ))
              
              :''}
  </>);
};

export default Tablerow;
