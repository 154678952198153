
import React, { Fragment, useEffect, useState } from 'react';

import {
  MenuIcon,
  SearchIcon,
  DownloadIcon,
  UserAddIcon,
  BellIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  GlobeIcon,
  UserIcon,
} from '@heroicons/react/solid';
import Cookies from 'js-cookie';
import { AdvButton } from '@adv/ui';
import { router } from '../../routes';
import { useNotifications } from "@mantine/notifications";
import { Link, useRouteActive } from 'react-typesafe-routes';
import { MultiSelect,Select } from '@mantine/core';
import { Dialog, Transition } from '@headlessui/react'
import Tablerow from './tablerow';
import { UserList,adminCreateUser,userRoles,getuserbymail,createOrganization,UserListbyorg ,Userdetails,allOrganizations} from '@adv/sdk/user';
import { FetchAllIndustries,CreateIndustry } from '@adv/sdk/Industry';
import {CreateWebsite} from '@adv/sdk/website';
import CountriesList from '@adv/sdk/countriesList';
import TimeZone from '@adv/sdk/timeZone';
const Organizations = () => {
  const notifications = useNotifications();
const [create, setCreate] = useState(false) 
const [industriesList, setIndustriesList] = useState<any[]>([])
const [allOrg, setallOrg] = useState<any>([])
//uservariables
 

const [errors, seterror] = useState('') 
const [orgName, setOrgname] = useState('') 
const [url, setUrl] = useState('')  //now its org id
//uservariables


const [country, setCountry] = useState(allOrg?.country) 
const [city, setCity] = useState(allOrg?.city) 
const [businessAddress, setBusinessAddress] = useState(allOrg?.businessAddress) 
const [zip, setZip] = useState(allOrg?.zip) 
const [timeZone,setTimezone]=useState(allOrg?.zip);
const [industry, setIndustry] = useState(allOrg?.type) 

const [curentUserroles, setcurentUserroles] = useState('')
const [rootRoleid,setrootRoleid]=useState('');
const [users, setUser] = useState<any>([])
const [tenant, setTenant] = useState<any[]>([])

const getallOrganizations = async () => {
    const { data } = await allOrganizations();
    if(data){
      setallOrg(data);
     console.log(data);
    }
}
const getallIndustries = async () => {
  let newgp = [{label:'No Industries yet',value:''}];
  const { data } = await FetchAllIndustries();
  let i=0;
  data?.map((p:any, j:any)=>{
    if(i==0){
      newgp = [{label:p.name,value:p._id}];
   } else  {
     newgp = [...newgp,{label:p.name,value:p._id}];   
   }
     i++;
  })
  setIndustriesList(newgp)
}
const createOrg = async()=>{
  const industrydata = {
    name: orgName,
    description: '',
    type: industry,
    logo: "",
    country:country,
    city:city,
    businessAddress:businessAddress,
    zip:zip,
    timezone:timeZone,
  };
  const { org } = await createOrganization(industrydata);
  if(org===undefined){
    seterror('Organization already exists!, Please change and try again')
  } else { 
    let tenant = org?.data['_id'];
    seterror('');
    const website = {
      name: orgName,
      url: url,
      orgId:tenant
    }
    const  {data}  = await CreateWebsite(website);
    getallOrganizations();
  }
  setCreate(false)
}

const getuserdetails = async()=> {
  const  data  = await Userdetails();
  if(data?.data?.user?._id!=undefined){
        console.log(data?.data)
        setTenant(data?.data?.user?.tenant)
        setcurentUserroles(data?.data.roles[0])
        setUser(data?.data)
  }
}
const userRole = async()=>{ 
  let newgp = [{label:'No Roles yet',value:'none'}];
  const { data } = await userRoles();
  let i=0;
  data.project?.map((p:any, j:any)=>{
    if(p.name=='root'){
      setrootRoleid(p._id)
    }
  })
}
useEffect(() => {
  userRole();
  getuserdetails();
  getallOrganizations();
  getallIndustries();

}, []); 
  return (<>
<div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
    <div className="bg-gray-800 pt-3">
        <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 p-4 shadow text-2xl ">
            <h3 className="font-bold pl-2 mt-2">Organizations</h3>
        </div>
    </div>
<div className='flex bg-gray-200 py-2'>
  <div className='flex-1 flex'>
    <div className='relative'>      
    <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2' />
    <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
    </div>
    
  </div>
  <div className='flex-1 text-right pr-3'>
    {/* <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500 relative pr-7 pl-2 text-xs h-9 font-bold
    hover:bg-black hover:text-white transition-all'>
       Export
        <DownloadIcon className='h-6 absolute top-1.5 right-1'/>
    </button> */}
    <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500  pr-7 pl-2 text-xs font-bold h-9 relative ml-2
    hover:bg-black hover:text-white transition-all' onClick={() => {setCreate(true)}}>Create Organization
      <UserAddIcon className='h-6 absolute top-1.5 right-1'/>
    </button>
  </div>
</div>
    <div className="flex flex-wrap">
    <table className="table-auto flex-1 mx-3 bg-white rounded-xl overflow-hidden shadow-lg text-left mt-3">
      <thead className='bg-gradient-to-r from-black to-gray-700 text-white '>
        <tr>
          <th  className='py-2 w-10'>
          </th>
          <th  className='py-2 '>Name</th>
          <th  className='py-2'>Address</th>
          <th  className='py-2'>city</th>
          <th  className='py-2'>country</th>
          <th  className='py-2'>type</th>
          <th  className='py-2 w-14'></th>
        </tr>
      </thead>
      <tbody>
        {allOrg.map((org:any,i:any)=>(
         tenant.indexOf( org._id) > -1||rootRoleid==users.roles?
        <Tablerow  onSelects={(step) => getallOrganizations()}   industriesList={industriesList} allOrg={org} ></Tablerow>:''
        )
        )}
      </tbody>
    </table>
    </div>
</div>



<Transition.Root show={create} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto"  onClose={()=>{setCreate(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                     Create Organization.
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      <button onClick={() => setCreate(false)} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
              <div className='px-4 '>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Organization Name</div>
                    <input type='text' name="name"  onChange={(ev)=>setOrgname(ev.target.value)}  value={orgName}
                    className='w-full text-sm border rounded-lg' placeholder='Organization Name' required></input> 
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Industry</div>
                    <Select className='w-full text-sm border rounded-lg' value={industry}
                      placeholder="Select Industry" searchable data={industriesList} onChange={(e) => setIndustry(e)} />
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Country</div>
                    <Select className='capitalize  rounded-md'
                      placeholder="Select Country" searchable data={CountriesList} value={country} onChange={(ev:any)=>setCountry(ev)} />
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>City</div>
                    <input type='text' name="city"  onChange={(ev)=>setCity(ev.target.value)}  value={city}
                    className='w-full text-sm border rounded-lg' placeholder='Add City' required></input> 
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Organization Website</div>
                    <input type='text' name="url"  onChange={(ev)=>setUrl(ev.target.value)}  value={url}
                    className='w-full text-sm border rounded-lg' placeholder='Website URL' required></input> 
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Address</div>
                    <input type='text' name="city"  onChange={(ev)=>setBusinessAddress(ev.target.value)}   value={businessAddress}
                    className='w-full text-sm border rounded-lg' placeholder='Address' required></input> 
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Zip/Postal code</div>
                    <input type='text' name="city"  onChange={(ev)=>setZip(ev.target.value)}   value={zip}
                    className='w-full text-sm border rounded-lg' placeholder='Zip/Postal code' required></input> 
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Time Zone</div>
                    <Select className='capitalize  rounded-md'
                    placeholder="Select timezone"  data={TimeZone} value={timeZone} onChange={(ev:any)=>setTimezone(ev)} />
                  </div>
                  {errors!=''? <div className='pt-2 text-red-400'>{errors}</div>:''}
                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">
                  
                  <AdvButton 
                    color="green"
                    onClick={() =>createOrg()}>
                    Create
                  </AdvButton>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root> 
 </> );
};

export default Organizations;
