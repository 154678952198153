
import React, { Fragment, useEffect, useState } from 'react';

import {
  MenuIcon,
  SearchIcon,
  DownloadIcon,
  UserAddIcon,
  BellIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  GlobeIcon,
  UserIcon,
} from '@heroicons/react/solid';
import Cookies from 'js-cookie';
import { AdvButton } from '@adv/ui';
import { router } from '../../routes';
import { useNotifications } from "@mantine/notifications";
import { Link, useRouteActive,useRouteParams } from 'react-typesafe-routes';
import { MultiSelect,Select } from '@mantine/core';
import { Dialog, Transition } from '@headlessui/react'
import Tablerow from './tablerow';
import { permisionsByOrg } from '@adv/sdk/permissions';
import CountriesList from '@adv/sdk/countriesList';
import TimeZone from '@adv/sdk/timeZone';
import { UserList,adminCreateUser,userRoles,getuserbymail,createOrganization,UserListbyorg ,Userdetails,allOrganizations} from '@adv/sdk/user';
const OrganizationsPermisions = () => {
const options = useRouteParams(router.OrganizationsPermisions);
const [permisions, SetPermisions] = useState<any[]>([])

const orgPermisions = async () => {
  const { data } = await permisionsByOrg(options.orgID);
  let newgp = [{_id:'',module:'',orgId:'',permissionLabel:'',permissionAction:'',userPermission: {admin: true, user: true, guest: true},sub:[{}],type:''}];
  data.sort(function(a:any, b:any) {
    return compareStrings(a.module, b.module);
  })
  data.map((p:any,i:any)=>{
if(i==0){
  newgp = [{_id:p._id,module:p.module,orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission,sub:[{}],type:''}];
} else {
  if(p.module==data[i-1].module){
    if(p.permissionAction=='create'||p.permissionAction=='list'||p.permissionAction=='edit'||p.permissionAction=='delete'||p.permissionAction=='play'){
      newgp = [...newgp,{_id:p._id,module:p.module,orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission,sub:[{}],type:''}];
    } else if(p.module=='sideMenu') {
      if(p.permissionLabel=="Design Dashboard"||p.permissionLabel=='Project'||p.permissionLabel=='Ad Templates'||p.permissionLabel=='Email Templates'||p.permissionLabel=='Automation'||p.permissionLabel=='Html Ad'||p.permissionLabel=='Landing Pages'||p.permissionLabel=='Uploads'){
        newgp = [...newgp,{_id:p._id,module:'designeditor Menu',orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission,sub:[{}],type:'designeditor'}];
      } else if (p.permissionLabel=="Search Ad"||p.permissionLabel=='Project') {
        newgp = [...newgp,{_id:p._id,module:'ad Manager Menu',orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission,sub:[{}],type:'adManager'}];
      } else {
        newgp = [...newgp,{_id:p._id,module:'campaign Menu',orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission,sub:[{}],type:'campaign'}];
      }
    } else {
      if(p.permissionLabel.includes('View')||p.permissionLabel.includes('List')){
        newgp.map((n:any,j:any)=>{
          if(n.module==p.module&&n.permissionAction=='list'){
            newgp[j].sub= [...newgp[j].sub,{
              _id:p._id,module:p.module,orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission
            }]
          }
        })
     } else if(p.permissionLabel.includes('Create')) {
      newgp.map((n:any,j:any)=>{
        if(n.module==p.module&&n.permissionAction=='create'){
          newgp[j].sub= [...newgp[j].sub,{
            _id:p._id,module:p.module,orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission
          }]
        }
      })
     }else if(p.permissionLabel.includes('Edit')) {
      newgp.map((n:any,j:any)=>{
        if(n.module==p.module&&n.permissionAction=='edit'){
          newgp[j].sub= [...newgp[j].sub,{
            _id:p._id,module:p.module,orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission
          }]
        }
      })
     }else if(p.permissionLabel.includes('Delete')) {
      newgp.map((n:any,j:any)=>{
        if(n.module==p.module&&n.permissionAction=='delete'){
          newgp[j].sub= [...newgp[j].sub,{
            _id:p._id,module:p.module,orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission
          }]
        }
      })
     }
    }

  } else {
    newgp = [...newgp,{_id:p._id,module:p.module,orgId:p.orgId,permissionLabel:p.permissionLabel,permissionAction:p.permissionAction,userPermission: p.userPermission,sub:[{}],type:''}];
  }
}
  })
  // console.log(data);
  // console.log(newgp);
  SetPermisions(newgp);
}


const [user, setUser] = useState<any>([])
const [rootRoleid,setrootRoleid]=useState('');
const getuserdetails = async()=> {
  const  data  = await Userdetails();
  if(data?.data?.user?._id!=undefined){
        console.log(data?.data)
        setUser(data?.data)
  }
}
const userRole = async()=>{ 
  let newgp = [{label:'No Roles yet',value:'none'}];
  const { data } = await userRoles();
  let i=0;
  data.project?.map((p:any, j:any)=>{
    if(p.name=='root'){
      setrootRoleid(p._id)
    }
  })
}

function compareStrings(a:any, b:any) {
  // Assuming you want case-insensitive comparison
  a = a.toLowerCase();
  b = b.toLowerCase();
  return (a < b) ? -1 : (a > b) ? 1 : 0;
}

useEffect(() => {
  userRole()
  orgPermisions();
  getuserdetails();
}, []); 
  return (<>
<div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
    <div className="bg-gray-800 pt-3">
        <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 p-4 shadow text-2xl ">
            <h3 className="font-bold pl-2 mt-2">{options.orgName} Organization Permissions</h3>
        </div>
    </div>
<div className='flex bg-gray-200 py-2'>

</div>
  
    <div className="m-3 border-2 rounded-t-lg">
      
      <div className='w-full grid grid-cols-6 p-3 font-bold text-lg capitalize bg-gradient-to-r from-black to-gray-700 text-white rounded-t-lg mb-1'>
        <div className=''>Modules</div>
        <div className=''>Operations</div>
        <div className="text-center">Admin</div>
        <div className="text-center">User</div>
        <div className="text-center">Guest</div>
        <div className="text-center"></div>
      </div>
      {(user?.user?.apps?.designEditor==true||user?.roles==rootRoleid)&&<>
      <div className="font-bold text-xl p-4 bg-gray-400">Design Editor</div>
        {permisions.map((p,i)=>(<>
     
          {p.module=='design'||p.module=='template'||p.module=='automation'||p.module=='asset'||p.module=='projects'||p.module=='designeditor Menu'?<>
            <div className='w-full grid grid-cols-6'>
              {i>0? 
                permisions[i-1].module==permisions[i].module?
                  '':
                  <div className='p-3 font-bold text-lg capitalize border-t-2'>{p.module}</div>
                :
                  <div className='p-3 font-bold text-lg capitalize border-t-2'>{p.module}</div>
              }
              {p.permissionLabel!='Active Response Dashboard'&&p.permissionLabel!='Leads'&&p.permissionLabel!='Campaign'&&p.permissionLabel!='Segment'&&p.permissionLabel!='Visitors'&&p.permissionLabel!='Configure'&&p.permissionLabel!='Sequence'&&p.permissionLabel!='Search Ad'?<>
                {i>0? 
                  permisions[i-1].module==permisions[i].module?
                    <div className='p-3 font-bold text-lg capitalize '></div>
                  :''
                :''}
                <div  className='col-span-5 border-t-2 border-l-2'>
                  <Tablerow permisions={p}  onSelects={(step) => orgPermisions()} ></Tablerow>
                </div>
                </>:
              ''}
            </div>
          </>:''}
          
        </>))}
        </> }
        <div className="border-t-2"></div>
        {(user?.user?.apps?.activeResponse==true||user?.roles==rootRoleid)&&<>
        <div className="font-bold text-xl p-4 bg-gray-400">Active Response</div>
        {permisions.map((p,i)=>(<>
     
            {p.module=='campaign'||p.module=='segment'||p.module=='customer'||p.module=='workflow'||p.module=='statistics'||p.module=='website'||p.module=='campaign Menu'?<>
             
                <div className='w-full grid grid-cols-6'>
                {i>0? 
                  permisions[i-1].module==permisions[i].module?
                    '':
                    <div className='p-3 font-bold text-lg capitalize border-t-2'>{p.module=='customer'?'leads':p.module}</div>
                  :
                    <div className='p-3 font-bold text-lg capitalize border-t-2'>{p.module=='customer'?'leads':p.module}</div>
                }
                {p.permissionLabel!='Design Dashboard'&&p.permissionLabel!='Project'&&p.permissionLabel!='Ad Templates'&&p.permissionLabel!='Email Templates'&&p.permissionLabel!='Automation'&&p.permissionLabel!='Html Ad'&&p.permissionLabel!='Landing Pages'&&p.permissionLabel!='Uploads'?<>
                  {i>0? 
                    permisions[i-1].module==permisions[i].module?
                      <div className='p-3 font-bold text-lg capitalize '></div>
                    :''
                  :''}
                  <div  className='col-span-5 border-t-2 border-l-2'>
                    <Tablerow permisions={p}  onSelects={(step) => orgPermisions()} ></Tablerow>
                  </div> 
                  </>:
                ''}
              </div>
            </>:''}
            
          </>))}
          </>}
          <div className="border-t-2"></div>
          
          <div className="font-bold text-xl p-4 bg-gray-400">Ad Manager</div>
          {permisions.map((p,i)=>(<>
     
            {p.module=="social_design"||p.module=="social_keyword"||p.module=="ad Manager Menu"?<>
             
                <div className='w-full grid grid-cols-6'>
                {i>0? 
                  permisions[i-1].module==permisions[i].module?
                    '':
                    <div className='p-3 font-bold text-lg capitalize border-t-2'>{p.module}</div>
                  :
                    <div className='p-3 font-bold text-lg capitalize border-t-2'>{p.module}</div>
                }
                {p.permissionLabel!='Design Dashboard'&&p.permissionLabel!='Project'&&p.permissionLabel!='Ad Templates'&&p.permissionLabel!='Email Templates'&&p.permissionLabel!='Automation'&&p.permissionLabel!='Html Ad'&&p.permissionLabel!='Landing Pages'&&p.permissionLabel!='Uploads'?<>
                  {i>0? 
                    permisions[i-1].module==permisions[i].module?
                      <div className='p-3 font-bold text-lg capitalize '></div>
                    :''
                  :''}
                  <div  className='col-span-5 border-t-2 border-l-2'>
                    <Tablerow permisions={p}  onSelects={(step) => orgPermisions()} ></Tablerow>
                  </div> 
                  </>:
                ''}
              </div>
            </>:''}
            
          </>))}
          {(user?.user?.apps?.teams==true||user?.roles==rootRoleid)&&<>
          <div className="font-bold text-xl p-4 bg-gray-400">Teams</div>
          {permisions.map((p,i)=>(<>
     
            {p.module=="users"?<>
             
                <div className='w-full grid grid-cols-6'>
                {i>0? 
                  permisions[i-1].module==permisions[i].module?
                    '':
                    <div className='p-3 font-bold text-lg capitalize border-t-2'>{p.module}</div>
                  :
                    <div className='p-3 font-bold text-lg capitalize border-t-2'>{p.module}</div>
                }
                {p.permissionLabel!='Design Dashboard'&&p.permissionLabel!='Project'&&p.permissionLabel!='Ad Templates'&&p.permissionLabel!='Email Templates'&&p.permissionLabel!='Automation'&&p.permissionLabel!='Html Ad'&&p.permissionLabel!='Landing Pages'&&p.permissionLabel!='Uploads'?<>
                  {i>0? 
                    permisions[i-1].module==permisions[i].module?
                      <div className='p-3 font-bold text-lg capitalize '></div>
                    :''
                  :''}
                  <div  className='col-span-5 border-t-2 border-l-2'>
                    <Tablerow permisions={p}  onSelects={(step) => orgPermisions()} ></Tablerow>
                  </div> 
                  </>:
                ''}
              </div>
            </>:''}
            
          </>))}
          </>}
    </div>
</div>




 </> );
};

export default OrganizationsPermisions;
