
import React, { Fragment, useEffect, useState,FunctionComponent,useRef } from 'react';
import { router } from '../../routes';
import { Link, useRouteActive } from 'react-typesafe-routes';
import { AdvButton } from '@adv/ui';
import { Dialog, Transition } from '@headlessui/react'
import { useNotifications } from "@mantine/notifications";
import { MultiSelect,Select } from '@mantine/core';
import { DeleteGroup ,UpdateGroup} from '@adv/sdk/group';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { DeleteWebsite } from '@adv/sdk/website';
interface CustomProps {
  web:  any;
  Providers:  any[];
  onupdate:(newind: string) => void;
  }
const WebsiteCard: FunctionComponent<CustomProps> = ({
  web,Providers,onupdate
  }: CustomProps) => {
const notifications = useNotifications();
const [open, setOpen] = useState(false)
function limit(string = '', limit = 0) {
  return string.substring(0, limit)
}
const DeleteUser = async (a:string) => {
  const { data } = await DeleteWebsite(web._id);
  console.log(data);
  onupdate('hii')
  notifications.showNotification({
    title: "Website Deleted Successfully!",
    message: "",autoClose: 2000,
    color: 'lime',
    loading: false,
    disallowClose: false
  });

}
  return (<>
        <div className="w-full md:w-1/2 xl:w-1/4 p-6 capitalize">
            <div className='flex rounded-2xl overflow-hidden shadow-md'>
              <div className='flex-1 bg-gradient-to-r from-black to-gray-700 py-2 pl-4 relative text-white relative pb-4'>
                <div className='text-base mt-3  flex bg-green'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-11 w-11" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                  <div className='text-md flex-1  mr-2 text-left pl-3' title={web.name}>
                  {web.name.length<=16? web.name:<>{limit(web.name, 16)}...</>}
                    <div className='text-xs '>{format(new Date(web.createdAt), 'dd-MM-yyyy')}</div>
                  </div>
                </div>
                <div className='text-xs flex my-1 mt-2'>

                </div>
                <div className='text-lg flex my-1'>
                  <div className='w-1/3 '>pages</div>
                  <div className='w-3/4 text-xs mt-1'>
                    <a href={web.pages?.home} className='mb-1 inline-block border-blue-50 border-b' target="_blank">Home page</a><br />
                    <a href={web.pages?.srp} className='mb-1 inline-block border-blue-50 border-b' target="_blank">Search Result</a><br />
                    {/* <a href={web.pages?.vdp} className='mb-1 inline-block border-blue-50 border-b' target="_blank">Vehicle Listing</a><br />
                    <a href={web.pages?.vlp} className='mb-1 inline-block border-blue-50 border-b' target="_blank">Vehicle Details</a> */}
                  </div>
                </div>
                <div className='text-sm flex my-1'>
                  <div className='w-1/3  '>provider: </div><div className='w-3/4 '>
                    {Providers.map((pro,i)=>(
                      pro.value==web.provider? pro.label:''
                    ))}
                  </div>
                </div>
                <Link to={router.WebsiteMetadata({ id: web._id, name: web.name,provider:web.provider||'nulls'})}
                 className="bg-white text-black text-xs px-4 py-1 rounded-lg relative right-3 top-1  mb-2 shadow-lg mt-3 ml-2 ">
                  Details
                </Link>
              </div>
              <div className=' bg-white py-2 px-1 flex flex-col'>
                <button title="edit" className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                </button>
                <button title="embed code" className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                  </svg>
                </button>
                <button onClick={() => setOpen(true)}
                title="delete" className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
        </div>


        <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg py-4 border  bg-white   text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 ">
                    <Dialog.Title as="h3" className="text-base leading-6 font-medium text-gray-900 text-center">
                      Are you sure you want to delete Website  <b>"{web.name}"</b> ?
                    </Dialog.Title>
                    {/* <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this Lead?
                        This action cannot be undone.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className=" px-4 py-3 sm:px-6 flex justify-center">
                <AdvButton
                  outline={true}
                  color="green"
                  className="w-28 mr-2 "
                  onClick={() => setOpen(false)} >
                  Cancel
                </AdvButton>
                <AdvButton
                  color="green"
                  className="w-28"
                  onClick={() => {setOpen(false);DeleteUser('g')}}>
                  Yes
                </AdvButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  </>);
};

export default WebsiteCard;
