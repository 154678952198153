import { Api } from '@adv/utils/api';
import { stringify } from 'querystring';

import Cookies from 'js-cookie';
var token= Cookies.get('token');
const config = {
  headers: { Authorization: `Bearer ${token}` }
};
const url = `website-traits`;
export const FetchAllWebsiteTraits = async () => {

  try {
    const res = await Api.get(url,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const FetchAllWebsiteTraitsbyId = async (id: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  try {
    const res = await Api.get(`${url}/website/${id}`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const DeleteWebsiteProperties = async (id: string) => {
  try {
    const res = await Api.delete(`${url}/${id}`,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const CreateWebsiteProperties = async (data: any) => {
  try {
    const res = await Api.post(`${url}`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const UpdateWebsiteProperties = async (id: string, data: any) => {
  try {
    const res = await Api.put(`${url}/${id}`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
// export const DeleteIndustry = async (id: string) => {
//   try {
//     const res = await Api.delete(`${url}/${id}`);
//     return {
//       status: 200,
//       data: res || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
// export const FetchAllindustryProviders = async () => {
//   try {
//     const res = await Api.get('industry-providers');
//     return {
//       status: res.status || 200,
//       data: res.data || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
// export const industryProvidersById = async (
//   id: string
// ) => {
//   try {
//     const res = await Api.get(`industry-providers/${id}`);
//     return {
//       status: +res.status || 200,
//       data: res.data || null,
//     };
//   } catch (error) {
//     return { status: 400, error, data: null };
//   }
// };
// export const Providerswebsite = async () => {
//   try {
//     const res = await Api.get('website');
//     return {
//       status: res.status || 200,
//       data: res.data || null,
//     };
//   } catch (error) {
//     return { status: 400, error };
//   }
// };
