import { ApiResult, Segments, SegmentsQuery } from '@adv/interface';
import { Api } from '@adv/utils/api';
import { stringify } from 'querystring';
import Cookies from 'js-cookie';
var token= Cookies.get('token');
const config = {
  headers: { Authorization: `Bearer ${token}` }
};
const url = `MetaDataGroups`;
export const FetchAllGroups = async () => {

  try {
    const res = await Api.get(url,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const UpdateGroup = async (id: string, data: any) => {
  try {
    const res = await Api.put(`${url}/${id}`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const CreateGroup = async (data: any) => {
  try {
    const res = await Api.post(`${url}`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const DeleteGroup = async (id: string) => {
  try {
    const res = await Api.delete(`${url}/${id}`,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const FetchAllPropertiesID = async (id: string) => {
  try {
    const res = await Api.get(`MetaDataProps`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const FetchAllProperties = async () => {
  try {
    const res = await Api.get('/industry-traits',config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const CreateProperties = async (data: any) => {
  try {
    const res = await Api.post(`/MetaDataProps`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const UpdateProperties = async (id: string, data: any) => {
  try {
    const res = await Api.patch(`industry-traits/${id}`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const DeleteProperties = async (id: string) => {
  try {
    const res = await Api.delete(`MetaDataProps/${id}`,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
