
import React, { Fragment, useEffect, useState,FunctionComponent } from 'react';

import {
  MenuIcon,
  SearchIcon,
  DownloadIcon,
  PlusIcon,
  BellIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  GlobeIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { router } from '../../routes';
import { Link, useRouteActive } from 'react-typesafe-routes';
import { useNotifications } from "@mantine/notifications";
import { Dialog, Transition } from '@headlessui/react'
import { AdvButton } from '@adv/ui';
import PropertiesTable from './propertiesTable';
import { MultiSelect,Select } from '@mantine/core';

import { FetchAllGroups,CreateProperties,FetchAllPropertiesID } from '@adv/sdk/group';
interface CustomProps {
  industryname: string;
  industryId: string;
}
  const Properties: FunctionComponent<CustomProps> = ({
    industryname,industryId
  }: CustomProps) => {
const notifications = useNotifications();
const [create, setcreate] = useState(false)
const [error, seterror] = useState(false)
const [search, setsearch] = useState('')
const [group, setgroup] = useState('')
const [searchgroup, setSearchgroup] = useState('')
const [searchfieldtype, setSearchfieldtype] = useState('')

const [name, setname] = useState('')
const [internalName, setinternalName] = useState('')
const [value,setvalue] = useState<any | undefined>([])
const [description, setdescription] = useState('')
const [unit, setunit] = useState('')
const [fieldType, setfieldType] = useState('')
  const fields = [
    { value: 'boolean', label: 'Boolean' },
    { value: 'date', label: 'Date' },
    { value: 'datetime', label: 'Date time' },
    { value: 'datetimeArray', label: 'Datetime Array' },
    { value: 'email', label: 'Email' },
    { value: 'guid', label: 'Guid' },
    { value: 'idArray', label: 'Id Array' },
    { value: 'array', label: 'Array' },
    { value: 'number', label: 'Number' },
    { value: 'string', label: 'String' },
    { value: 'select', label: 'Select' },
  ];
  const [groupdata,setgroupdata] = useState<any[]>([])
const [propertydata,setdata] = useState<any[]>([
    {id:'sdfg45',name:' ',field:' ',createdBy:' ',groupId:' ',usedIn:' ',
    options:  ['Organic Search', 'Paid Search', 'Email Marketing', 'Organic Social', 'Referrals', 'Other Campaigns', 'Direct Traffic', 'Offline Sources', 'Paid Social']
    },
    ,
])
const createProperty = async()=>{
  if(name==''||internalName==''||description==''||fieldType==''||group==''||internalName==''){
seterror(true)
  } else {
    seterror(false)
      const uploaddata = {
        PropName: name,
        MetadataGroup: group,
        // value: value,
        industry: industryId,
        InternalName: internalName,
        type: fieldType,
        description: description,
        unit: unit,
        key: name,
        index:"",
        direction:"DIR_OUT"
      };
  // console.log(uploaddata);
  const { data } = await CreateProperties(uploaddata);
  // console.log(data);
  notifications.showNotification({
      title: "Property Created Successfully!",
      message: "",autoClose: 2000,
      color: 'lime',
      loading: false,
      disallowClose: false
  });
  fetchproperties();
  setcreate(false)
  }

}
const fetchproperties = async () => {
  const { data } = await FetchAllPropertiesID(industryId);
  // console.log(data);
  setdata(data);
  // setMyTime(new Date());
}
const fetchgroups = async () => {
  let newgp = [{label:'No Groups yet',value:'none'}];
  const { data } = await FetchAllGroups();
  let i=0;
  // console.log(data.data)
  data?.map((group:any, j:any) => {
    // console.log(group.name)
    // if(industryId==group.industry._id){
          if(i==0){
       newgp = [{label:group.name,value:group.id}];
    } else  {
      newgp = [...newgp,{label:group.name,value:group.id}];
    }
      i++;
    // }
  })
  if(newgp[0]){
  console.log(newgp)
  }
  setgroupdata(newgp);
  // console.log(newgp);
}
useEffect(() => {
  fetchgroups();
  fetchproperties();
}, []);
return (<>
    <div className='flex bg-gray-200 py-2'>
        <div className='flex-1 flex pl-2'>
            <span className="font-bold text-sm mt-2">Filter By:</span>
            {groupdata[0]?.value=='none'?'':
              <Select className='ml-2 border border-gray-200 rounded-md text-xs w-44'
              placeholder="Group" searchable data={groupdata} value={searchgroup} onChange={(ev)=>setSearchgroup(ev!)} />
            }


            <Select className='ml-2 border border-gray-200 rounded-md text-xs w-44'
            placeholder="Type" searchable data={fields}  value={searchfieldtype} onChange={(ev)=>setSearchfieldtype(ev!)}/>
        </div>
        <div className='flex-1 text-right pr-3 flex justify-end'>
        <div className='relative'>
            <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2'
            onChange={(ev) => setsearch(ev.target.value)}/>
            <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
            </div>
        <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500  pr-7 pl-2 text-xs font-bold h-9 relative ml-2
        hover:bg-black hover:text-white transition-all' onClick={() => {setcreate(true)}}>Create Property
            <PlusIcon className='h-6 absolute top-1.5 right-1'/>
        </button>
        </div>
    </div>
    <div className="flex flex-wrap">
        <table className="table-auto flex-1 mx-3 bg-white rounded-xl overflow-hidden shadow-lg text-left mt-3">
            <thead className='bg-gradient-to-r from-black to-gray-700 text-white '>
                <tr>
                <th  className='py-2 w-10'>
                </th>
                <th  className='py-2 '>Name</th>
                <th  className='py-2 '>InternalName</th>
                <th  className='py-2 '>Type</th>
                <th  className='py-2 '>Unit</th>
                <th  className='py-2 '>Description</th>
                <th  className='py-2'>Group</th>
                <th  className='py-2'>Created</th>
                <th  className='py-2'>Used in</th>
                <th  className='py-2 w-14'></th>
                </tr>
            </thead>
            <tbody>
            {propertydata?.map((prop,i)=>(
              searchgroup==prop.group?.id||searchgroup==''?
                searchfieldtype==prop.type||searchfieldtype==''?
                  (prop.propName?.toLowerCase().includes(search?.toLowerCase())||prop.creator?.toLowerCase().includes(search?.toLowerCase()))?<>
                      <PropertiesTable groupdata={groupdata} industryId={industryId} user={prop}  onSelects={(step) => fetchproperties()}></PropertiesTable></>
                  :''

                :''
              :''
            ))}
             </tbody>
        </table>
    </div>



    <Transition.Root show={create} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto"  onClose={()=>{setcreate(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                      Create Property.
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                     <button onClick={() => setcreate(false)} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
                <div className='px-4 '>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Property Name</div>
                    <input type='text' name="name" onChange={(ev)=>setname(ev.target.value)}  className='w-full text-sm border rounded-lg' placeholder='Name of the Property' required></input>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Internal Name</div>
                    <input type='text' name="internalName"  onChange={(ev)=>setinternalName(ev.target.value)} className='w-full text-sm border rounded-lg' placeholder='Internal Name of the Property' required></input>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Field Type</div>
                    <Select className='w-full text-sm border rounded-lg'
                      placeholder="Pick one" searchable data={fields} value={fieldType} onChange={(e:string) => setfieldType(e)} />
                  </div>
                  {fieldType=='select'?
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Options</div>
                    <MultiSelect
                     data={['']}
                     onChange={(e)=>{setvalue(e)}}
                      placeholder="Pick all that you like"
                      searchable
                      creatable
                      value={value}
                      getCreateLabel={(query) => `+ Create ${query}`}
                    />
                  </div>
                  :''}
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Unit</div>
                    <input type='text' name="name" value={unit} onChange={(ev)=>setunit(ev.target.value)} className='w-full text-sm border rounded-lg' placeholder='Unit'></input>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Description</div>
                    <input type='text' name="name" onChange={(ev)=>setdescription(ev.target.value)} className='w-full text-sm border rounded-lg' placeholder='Description' required></input>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Add to group</div>
                    <Select className='w-full text-sm border rounded-lg'
                      placeholder="Pick one" searchable data={groupdata} onChange={(e:string) => setgroup(e)} />
                  </div>
                  {error? <div className='pt-2 text-red-400'>Fill all Fields</div>:''}
                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">

                  <AdvButton type="submit"
                    color="green"
                    onClick={() => {createProperty()}}>
                    Create
                  </AdvButton>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
</>)
};

export default Properties;
