

const TimeZone = [
    {
      "label": "(GMT-10:00) Hawaii Time",
      "value": "GMT-10:00"
    },
    {
      "label": "(GMT-09:00) Alaska Time",
      "value": "GMT-09:00"
    },
    {
      "label": "(GMT-08:00) Pacific Time",
      "value": "GMT-08:00"
    },
    {
      "label": "(GMT-07:00) Dawson Creek Time",
      "value": "GMT-07:00"
    },
    {
      "label": "(GMT-06:00) Central Time , Costa Rica Time, El Salvador Time",
      "value": "GMT-06:00"
    },
    {
      "label": "(GMT-05:00) Atikokan Time",
      "value": "GMT-05:00"
    },
    {
      "label": "(GMT-04:30) Caracas Time",
      "value": "GMT-04:30"
    },
    {
      "label": "(GMT-04:00) Blanc-Sablon Time",
      "value": "GMT-04:00"
    },
    {
      "label": "(GMT-03:30) St Johns Time",
      "value": "GMT-03:30"
    },
    {
      "label": "(GMT-03:00) Sao Paulo, Belem Time",
      "value": "GMT-03:00"
    },
    {
      "label": "(GMT-02:00) Noronha Time",
      "value": "GMT-02:00"
    },
    {
      "label": "(GMT-01:00) Azores Time",
      "value": "GMT-01:00"
    },
    {
      "label": "(GMT) Accra, Casablanca, Dublin , London, Reykjavik Time",
      "value": "GMT"
    },
    {
      "label": "(GMT+01:00) Amsterdam, Berlin, Lagos, Luxembourg, Malta, Tunis Time",
      "value": "GMT+01:00"
    },
    {
      "label": "(GMT+02:00) Johannesburg, Amman, Athens, Beirut,Cairo,Jerusalem, Istanbul Time",
      "value": "GMT+02:00"
    },
    {
      "label": "(GMT+03:00) Baghdad, Bahrain, Kuwait, Moscow, Qatar Time",
      "value": "GMT+03:00"
    },
    {
      "label": "(GMT+03:30)  Iran Time",
      "value": "GMT+03:30"
    },
    {
      "label": "(GMT+04:00) Dubai, Muscat, Samara Time",
      "value": "GMT+04:00"
    },
    {
      "label": "(GMT+05:00) Karachi, Maldives, Yekaterinburg Time",
      "value": "GMT+05:00"
    },
    {
      "label": "(GMT+05:30) Colombo, Kolkata Time",
      "value": "GMT+05:30"
    },
    {
      "label": "(GMT+06:00) Dhaka, Omsk Time",
      "value": "GMT+06:00"
    },
    {
      "label": "(GMT+07:00) Bangkok, Jakarta, Krasnoyarsk Time",
      "value": "GMT+07:00"
    },
    {
      "label": "(GMT+08:00) Singapore, Beijing, Hong Kong, Manila Time",
      "value": "GMT+08:00"
    },
    {
      "label": "(GMT+09:00) Tokyo, Jayapura, Seoul Time",
      "value": "GMT+09:00"
    },
    {
      "label": "(GMT+09:30) Broken Hill Time",
      "value": "GMT+09:30"
    },
    {
      "label": "(GMT+10:00) Vladivostok, Melbourne, Sydney Time",
      "value": "GMT+10:00"
    },
    {
      "label": "(GMT+11:00) Magadan Time",
      "value": "GMT+11:00"
    },
    {
      "label": "(GMT+12:00) Kamchatka, Auckland Time",
      "value": "GMT+12:00"
    }
  ]
export default TimeZone;