import { DesignFilterParams } from '@adv/interface';
import { Api } from '@adv/utils/api';
import { stringify } from 'querystring';
import Cookies from 'js-cookie';
var token= Cookies.get('token');
const config = {
  headers: { Authorization: `Bearer ${token}` }
};
export const getallLambdas = async () => {
  const url = `lambda/getLambdaFunctions/`;
  try {
    const res = await Api.get(`${url}`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const lambdaByIndustry = async (id: string) => {
  const url = `lambda/listByIndustryId`;
  try {
    const res = await Api.get(`${url}/${id}`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const getLambdaFunctions = async () => {
  const url = `lambda/getLambdaFunctions`;
  try {
    const res = await Api.get(`${url}/1000`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const CreateLambda = async (data: any) => {
  try {
    const res = await Api.post(`lambda`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const UpdateLambda = async (id: string, data: any) => {
  try {
    const res = await Api.put(`lambda/${id}`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const deletelambdas = async (id: string) => {
  const url = `lambda`;
  try {
    const res = await Api.delete(`${url}/${id}`,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};