
import React, { Fragment, useEffect, useState } from 'react';

import {  SearchIcon,  UserAddIcon,} from '@heroicons/react/solid';
import Cookies from 'js-cookie';
import { AdvButton } from '@adv/ui';
import { router } from '../../routes';
import { useNotifications } from "@mantine/notifications";
import { Link, useRouteActive,useRouteParams } from 'react-typesafe-routes';
import { MultiSelect,Select,Checkbox  } from '@mantine/core';
import { Dialog, Transition } from '@headlessui/react'
import Tablerow from './tablerow';
import { lambdaByIndustry,CreateLambda,getLambdaFunctions} from '@adv/sdk/lambda';
import { FetchAllIndustries,CreateIndustry,Providerswebsite } from '@adv/sdk/Industry';
const Lambda = () => {
  const notifications = useNotifications();
  const options = useRouteParams(router.Lambda);
  const [create, setCreate] = useState(false)
  const [lambdas, SetLambdas] = useState<any[]>([])
  const [arn, SetArn] = useState<any[]>([])
  const [properties,setProperties] = useState('{designId:"string",customerId:"string",mailSubj:"string",campaignId:"string",fromEmailId:"string",metaData:"",websiteId:"string"}')
  const [lambdaName, setLambdaName] = useState('')
  const [lambdaArn, setLambdaArn] = useState('')
const listLambdas = async () => {
      const { data } = await lambdaByIndustry(options.industryID);
      SetLambdas(data)
}
const lambdafunctions = async () => {
  let newgp = [{label:'No values yet yet',value:''}];
      const { data } = await getLambdaFunctions();
      let i=0;
      data.lambdas?.map((p:any, j:any)=>{
        if(i==0){
          newgp = [{label:p.FunctionName,value:p.FunctionArn}];
       } else  {
         newgp = [...newgp,{label:p.FunctionName,value:p.FunctionArn}];
       }
         i++;
      })
      SetArn(newgp)
}
const createLambda = async()=>{
  try {
    const uploaddata = {
      lambdaName: lambdaName,
      industryId: options.industryID,
      lambdaArn: lambdaArn,
      properties: properties,
    };
    const { status } = await CreateLambda(uploaddata);
    console.log(status);
    if(status==200){
      notifications.showNotification({
      title: "Lambda Created Successfully!",
      message: "",autoClose: 2000,
      color: 'yellow',
      loading: false,
      disallowClose: false
    });
    listLambdas ();
    setCreate(false)
    } else {
      notifications.showNotification({
        title: "Lambda Creation Error!",
        message: "",autoClose: 2000,
        color: 'red',
        loading: false,
        disallowClose: false
      });
      setCreate(false)
    }
  } catch (e) {
    notifications.showNotification({
      title: "Properties Content Invalid!",
      message: "Not a valid json string",autoClose: 2000,
      color: 'red',
      loading: false,
      disallowClose: false
    });
  }

}

useEffect(() => {
  listLambdas();
  lambdafunctions();

}, []);
  return (<>
<div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
  <div className="bg-gray-800 pt-3">
      <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 p-4 shadow text-2xl ">
          <h3 className="font-bold pl-2 mt-2">{options.industryName} Lambda</h3>
      </div>
  </div>
  <div className='flex bg-gray-200 py-2'>
    <div className='flex-1 flex'>
      <div className='relative'>
      <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2' />
      <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
      </div>
    </div>
    <div className='flex-1 text-right pr-3'>

      <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500  pr-7 pl-2 text-xs font-bold h-9 relative ml-2
      hover:bg-black hover:text-white transition-all' onClick={() => {setCreate(true)}}>Create Lambda
        <UserAddIcon className='h-6 absolute top-1.5 right-1'/>
      </button>
    </div>
  </div>
    <div className="flex flex-wrap">
    <table className="table-auto flex-1 mx-3 bg-white rounded-xl overflow-hidden shadow-lg text-left mt-3">
      <thead className='bg-gradient-to-r from-black to-gray-700 text-white '>
        <tr>
          <th  className='py-2 w-10'>
          </th>
          <th  className='py-2 '>Lambda Name</th>
          <th  className='py-2'>ARN</th>
          <th  className='py-2'></th>
        </tr>
      </thead>
      <tbody>
        {lambdas.map((lambda,i)=>(

        <Tablerow  onSelects={(step) => listLambdas()}  lambda={lambda} industryId={options.industryID} arn={arn}></Tablerow>
        )
        )}
      </tbody>
    </table>
    </div>
</div>



<Transition.Root show={create} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto"  onClose={()=>{setCreate(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                     Create Lambda.
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      <button onClick={() => setCreate(false)} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
                <div className='px-4 '>
                  <div className="">
                  <div className='pt-2 flex-1 mr-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Lambda Name</div>
                    <input type='email' name="email" onChange={(ev)=>setLambdaName(ev.target.value)} value={lambdaName}
                    className='w-full text-sm border rounded-lg' placeholder='Lambda name' required></input>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Lambda ARN</div>
                    <Select className='w-full text-sm border rounded-lg'
                      placeholder="Select ARN" searchable data={arn} onChange={(e:string) => setLambdaArn(e)} />
                  </div>
                  <div className='pt-2 flex-1 mr-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Properties</div>
                    <textarea  name="Content"  onChange={(ev)=>setProperties(ev.target.value)} className='w-full text-sm border rounded-lg h-60'
                   required>{properties}</textarea>
                  </div>

                 {/* <div className='pt-2 flex-1'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Second Name</div>
                    <input type='text' name="name" onChange={(ev)=>setsecondName(ev.target.value)}
                    className='w-full text-sm border rounded-lg' placeholder='Second name' required></input>
                  </div>
                  </div>
                  <div className="flex">
                    <div className='pt-2 flex-1'>
                      <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Phone number</div>
                      <input type='number' name="phone" onChange={(ev)=>setPhone(ev.target.value)}
                      className='w-full text-sm border rounded-lg' placeholder='Phone ' required ></input>
                    </div>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Password</div>
                    <input type='password' name="password" onChange={(ev)=>setpassword(ev.target.value)}
                    className='w-full text-sm border rounded-lg' placeholder='Password' required></input>
                  </div> */}

                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">

                  <AdvButton
                    color="green"
                    onClick={() =>createLambda()}>
                    Create
                  </AdvButton>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
 </> );
};

export default Lambda;
