
import React, { Fragment, useEffect, useState } from 'react';

import {
  MenuIcon,
  HomeIcon,
  CubeTransparentIcon,
  BellIcon,
  SearchIcon,
  CurrencyDollarIcon,
  GlobeIcon,
  UserIcon,
} from '@heroicons/react/solid';

const Subscriptions = () => {
 
  return (
<div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
    <div className="bg-gray-800 pt-3">
        <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 p-4 shadow text-2xl ">
            <h3 className="font-bold pl-2 mt-2">Subscriptions</h3>
        </div>
    </div>
<div className='flex bg-gray-200 py-2'>
  <div className='flex-1 flex'>
  <div className='relative'>      
      <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2' />
      <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
    </div>
  </div>
  <div className='flex-1  text-right pr-3'>
    <input type='date' className='ml-2 border border-gray-200 rounded-md text-xs'></input>
  </div>
</div>
    <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 xl:w-1/4 p-6">
            <div className='flex rounded-2xl overflow-hidden shadow-md'>
              <div className='flex-1 bg-gradient-to-r from-black to-gray-700 py-2 pl-4 relative text-white'>
                <div className='text-base mt-3  flex bg-green'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-11 w-11" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z" />
                    </svg>
                  <div className='text-sm mt-2 flex-1  mr-2 text-left pl-2'>subscription1
                    <div className='text-xs '>12-10-2021</div>
                  </div>
                </div>
                <div className='text-lg flex my-4'>
                  <CurrencyDollarIcon className='h-5 mr-2 mt-1' />
                    350/-
                </div>
                <div className='text-xs flex my-1'>
                  <div className='w-1/3 font-bold'>Type</div><div className='w-3/4'>Type one</div>
                </div>
                <div className='text-xs flex my-1'>
                  <div className='w-1/3 font-bold'>Status</div><div className='w-3/4'>Active</div>
                </div>
                <div className='text-xs flex my-1'>
                  <div className='w-1/3 font-bold'>Rules</div><div className='w-3/4'>Rule one <br/>Rule 2</div>
                </div>
              </div>
              <div className=' bg-white py-2 px-1 flex flex-col'>
                <button className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                </button>
                <button className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
        </div>
        
        <div className="w-full md:w-1/2 xl:w-1/4 p-6">
            <div className='flex rounded-2xl overflow-hidden shadow-md'>
              <div className='flex-1 bg-gradient-to-r from-black to-gray-700 py-2 pl-4 relative text-white'>
                <div className='text-base mt-3  flex bg-green'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-11 w-11" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z" />
                    </svg>
                  <div className='text-sm mt-2 flex-1  mr-2 text-left pl-2'>subscription1
                    <div className='text-xs '>12-10-2021</div>
                  </div>
                </div>
                <div className='text-lg flex my-4'>
                  <CurrencyDollarIcon className='h-5 mr-2 mt-1' />
                    350/-
                </div>
                <div className='text-xs flex my-1'>
                  <div className='w-1/3 font-bold'>Type</div><div className='w-3/4'>Type one</div>
                </div>
                <div className='text-xs flex my-1'>
                  <div className='w-1/3 font-bold'>Status</div><div className='w-3/4'>Activitie</div>
                </div>
                <div className='text-xs flex my-1'>
                  <div className='w-1/3 font-bold'>Rules</div><div className='w-3/4'>Rule one <br/>Rule 2</div>
                </div>
              </div>
              <div className=' bg-white py-2 px-1 flex flex-col'>
                <button className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                </button>
                <button className="bg-gray-200 text-black text-xs p-2 rounded-lg  top-1 mb-2 shadow-lg mt-3 ml-1 hover:bg-black hover:text-white transition-colors">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                </button>
              </div>
            </div>
        </div>
        
    </div>
</div> 
  );
};

export default Subscriptions;
