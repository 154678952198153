import { ApiResult, Website } from '@adv/interface';
import { Api } from '@adv/utils/api';
import Cookies from 'js-cookie';
var token= Cookies.get('token');
const config = {
  headers: { Authorization: `Bearer ${token}` }
};
const url = `website`;
export const FetchAllWebsites = async (): Promise<ApiResult<Website[]>> => {
  try {
    const res = await Api.get(url,config);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const GetWebsiteById = async (
  id: string
): Promise<ApiResult<Website>> => {
  try {
    const res = await Api.get(`${url}/${id}`);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error, data: null };
  }
};

export const CreateWebsite = async (
  data: Partial<Website>
): Promise<ApiResult<Website>> => {
  try {
    const res = await Api.post(url, data,config);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const UpdateWebsite = async (
  id: string,
  data: Partial<Website>
): Promise<ApiResult<Website>> => {
  try {
    const res = await Api.patch(`${url}/${id}`, data);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};

export const getIndustryProviders = async (): Promise<ApiResult<unknown>> => {
  const providerUrl = 'industry-providers';
  try {
    const res = await Api.get(providerUrl,config);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const DeleteWebsite = async (id: string) => {
  try {
    const res = await Api.delete(`${url}/${id}`);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};