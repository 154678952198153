
import React, { Fragment, useEffect, useState,FunctionComponent,useRef } from 'react';
import { router } from '../../routes';
import { Link, useRouteActive } from 'react-typesafe-routes';
import { AdvButton } from '@adv/ui';
import { Dialog, Transition } from '@headlessui/react'
import { useNotifications } from "@mantine/notifications";
import {format,parseISO} from 'date-fns';
import { MultiSelect,Select,Checkbox } from '@mantine/core';
import { adminDeleteUsers,UpdateUser,getuserbymail,createOrganization } from '@adv/sdk/user';
interface tenent {
  _id: string;
  name:string;
  type:string;
  }
interface userdata {
    name: string;
    secondName: string;
    access:string;
    email: string;
    phone: string;
    roles: string;
    designation: string;
    lastLoggedIn: string;
    tenant: any[];
    website: any[];
    _id: string;
    apps:any;
  }
interface CustomProps {
    user: userdata;
    roleslist: any[];
    industriesList: any[];
    websiteList: any[];
    allOrg: any[];
    onSelects:(newind: string) => void;
    curentUserroles:string;
    rootRoleid:string;
    edituser:string;
    deleteuser:string;
  }
const Tablerow: FunctionComponent<CustomProps> = ({
    user,onSelects,roleslist,industriesList,websiteList,allOrg,curentUserroles,rootRoleid,edituser,deleteuser
  }: CustomProps) => {
const [open, setOpen] = useState(false)
const [block, setblock] = useState(false)
const [edit, setedit] = useState(false)
const cancelButtonRef = useRef(null)
const notifications = useNotifications();


const [designation, setDesignation] = useState(user.designation)
const [phone, setPhone] = useState(user.phone)
const [email, setemail] = useState(user.email)
const [password, setpassword] = useState('')
const [name, setname] = useState(user.name)
const [secondName, setsecondName] = useState(user.secondName)
const [roles, setroles] = useState(user.roles[0])
const [industry, setindustry] = useState(user.tenant[0]?.type)
const [errors, seterror] = useState('')
const [orgName, setOrgname] = useState([])
const [website, setWebsite] =  useState<any[]>()
const [selectedwebsiteList, setSelectedWebsiteList] = useState<any[]>([]) //list after filter by org
//  active apps designEditor activeResponse, superAdmin, teams, setting
const [designEditor, setDesignEditor] = useState(true)
const [activeResponse, setActiveResponse] = useState(false)
const [superAdmin, setSuperAdmin] = useState(false)
const [teams, setTeams] = useState(true)
const [setting, setSetting] = useState(true)
const [adManager, setAdManager] = useState(false)
// active apps end

const uploaddata = async()=> {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

  if(email==''||name==''||roles==''||orgName==[]||industry==''){
    seterror('Please fill all fields')
    console.log(industry)
  } else {
    let err = false
    let newemail = user.email;
    let newtenant = [user.tenant[0]?._id];
    if(email!=user.email) {
      if(!pattern.test(email)) {
        seterror('Email Format is not correct!')
        err=true
      } else {
        seterror('');err=false
        const  status  = await getuserbymail(email);
        if(status.status!=400){
          seterror('Email already exists');err=true
        } else {
          seterror('') ;err=false;
        }
      }
    }
    if(curentUserroles==rootRoleid) {
      if(orgName==[]) {
        seterror('Organization not selected!')
      } else {
        newtenant = orgName;
      }
    } else {
      newtenant = [user.tenant[0]?._id];
    }
    // else if(orgName!=user.tenant[0]?.name){
    //   const industrydata = {
    //     name: orgName,
    //     description: '',
    //     type: industry,
    //     logo: ""
    //   };
      // const { org } = await createOrganization(industrydata);
      // if(org===undefined){
      //   seterror('Organization already exists!, Please change and try again');err=true;
      // } else {
      //   seterror('');err=false;
      //   newtenant = org.data['_id'];
      // }
        // seterror('');err=false;
    // }  else {
    //   console.log('org  same')
    // }
    let userdata = {};
      if(err==false){
        if(password==''){
         userdata = {
            email: newemail,
            name: name,
            secondName: secondName,
            phone: phone,
            designation: designation,
            roles:[roles],
            website:website,
            tenant: newtenant,
            verified: true,
            type: "BUSINESS",
            apps:{
              designEditor:designEditor,
              activeResponse:activeResponse,
              superAdmin:superAdmin,
              teams:teams,
              setting:setting,
              adManager:adManager,
            }
          };
        } else {
          userdata = {
            email: newemail,
            password: password,
            name: name,
            secondName: secondName,
            phone: phone,
            designation: designation,
            roles:[roles],
            website:website,
            tenant: newtenant,
            verified: true,
            type: "BUSINESS",
            apps:{
              designEditor:designEditor,
              activeResponse:activeResponse,
              superAdmin:superAdmin,
              teams:teams,
              setting:setting,
            }
          };
        }
        console.log(userdata);
        const { error } = await UpdateUser(user._id, userdata);
        notifications.showNotification({
          title: "User Updated Successfully!",
          message: "",autoClose: 2000,
          color: 'lime',
          loading: false,
          disallowClose: false
        });
        onSelects('hii')
       console.log(userdata);
       setedit(false);
      }

  }


}

const DeleteUser = async () => {
  const { data } = await adminDeleteUsers(user._id);
  notifications.showNotification({
    title: "User Deleted Successfully!",
    message: "",autoClose: 2000,
    color: 'lime',
    loading: false,
    disallowClose: false
  });
  onSelects('hii')
  // const { data } = await DeleteCustomer(_id);
  // getallcustomers();
}
const setappvalues = async()=>{
  if(user.apps!=undefined){
      setDesignEditor(user.apps.designEditor);
      setActiveResponse(user.apps.activeResponse);
      setSuperAdmin(user.apps.superAdmin);
      setTeams(user.apps.teams);
      setSetting(user.apps.setting);
      setAdManager(user.apps.adManager);
  }

}
const selectedWebsites = async (orgid:any,type='first')=> {
  console.log(orgid)
  setOrgname(orgid);
  setWebsite([])
  let newgp = [{label:'No websites yet',value:'',orgId:''}];

  let j=0;
  if(type!='first'){
      setWebsite([])
  }
try{
    orgid.map((org:any)=>{
    let i=0;
    websiteList.map((p:any)=>{
      if(p.orgId==org){
        if(i==0 && j==0){
          newgp = [{label:p.label,value:p.value,orgId:p.orgId}];
        } else  {
          newgp = [...newgp,{label:p.label,value:p.value,orgId:p.orgId}];
        }
        i++;
      }
    })
    j++;
  })
} catch {

}

  setSelectedWebsiteList(newgp)
}
const tenentseting = async()=> {
  let a:any =[]
  user.tenant.map((ten:any)=>{
    a= [...a,ten._id];
  })
  console.log(a);
  setOrgname(a);
  selectedWebsites(a);
}
useEffect(() => {
  tenentseting()
  if(user?.website?.length>0){
    setWebsite(user?.website)
  }
  if(orgName){
    // selectedWebsites(orgName);
  }
  setappvalues()
}, []);
  return (<>
        <tr className='border-b border-gray-300 tablebox hover:bg-gray-300 capitalize'>
          <td className='py-5  pl-2 text-appcolor-dark1'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg>
          </td>
          <td>{user.name} {user.secondName}<div className='text-xs bg-appcolor-dark2 table-cell px-1 rounded text-white'>{user.access}</div></td>
          <td className='lowercase'>{user.email}</td>
          <td>
            {roleslist.map((role,i)=>(
              role.value==user.roles?
              role.label:''
              ))}
          </td>
          <td className='lowercase'>{user.designation}</td>
          <td>
            {industriesList.map((indus,i)=>(
              indus.value==user.tenant[0]?.type?
              indus.label:''
              ))}
          </td>
          <td>{user.tenant[0]?.name}</td>
          <td>{user.lastLoggedIn!=null? format(parseISO(user.lastLoggedIn), 'MM/dd/yyyy hh:mm') :''}</td>
          <td className='tablecontent flex  pt-1.5  pr-1'>
            {edituser=='edit'&&
            <button className="bg-gray-200 text-black text-xs p-2 rounded-lg  shadow-lg ml-1 hover:bg-black hover:text-white transition-colors"
              onClick={() => setedit(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
            </button>}
            {deleteuser=='delete'&&
            <button className="bg-gray-200 text-black text-xs p-2 rounded-lg shadow-lg ml-1 hover:bg-black hover:text-white transition-colors"
             onClick={() => setOpen(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>}
          </td>
        </tr>
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg py-4 border  bg-white   text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 ">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 text-center">
                      Are you sure you want to delete user <b>"{user.name}"</b> ?
                    </Dialog.Title>
                    {/* <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this Lead?
                        This action cannot be undone.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className=" px-4 py-3 sm:px-6 flex justify-center">
                <AdvButton
                  outline={true}
                  color="green"
                  className="w-28 mr-2 "
                  onClick={() => setOpen(false)} >
                  Cancel
                </AdvButton>
                <AdvButton
                  color="green"
                  className="w-28"
                  onClick={() => {setOpen(false);DeleteUser()}}>
                  Yes
                </AdvButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>



    <Transition.Root show={edit} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={()=>{setedit(false);setblock(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                      Edit User <b>{user.name}</b> ?
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      {/* <button className='bg-red-500 mr-4 relative -top-2 text-white px-2 py-0.5 rounded text-sm' onClick={() => {setblock(!block)}}>Block User</button> */}
                      <button onClick={() => {setedit(false);setblock(false)}} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>

                    {/* <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this Lead?
                        This action cannot be undone.
                      </p>
                    </div> */}
                  </div>
              </div>
              {block==true?
              <div className='px-4 py-2 transition-all'>
                <div className='text-lg font-bold'>Do you want to block user "{user.name} "?</div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">
                <AdvButton
                  outline={true}
                  color="green"
                  className="w-28 mr-2 "
                  onClick={() => {setblock(false)}} >
                  Cancel
                </AdvButton>
                <AdvButton
                  color="green"
                  className="w-28"
                  onClick={() => {setedit(false);setblock(false)}}>
                  Yes
                </AdvButton>
              </div>
              </div>
              :<>
                <div className='px-4 '>
                <div className="flex">
                  <div className='pt-2 flex-1 mr-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>First Name</div>
                    <input type='text' name="name" onChange={(ev)=>setname(ev.target.value)} value={name}
                    className='w-full text-sm border rounded-lg' placeholder='First name' required></input>
                  </div>
                  <div className='pt-2 flex-1'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Second Name</div>
                    <input type='text' name="name" onChange={(ev)=>setsecondName(ev.target.value)} value={secondName}
                    className='w-full text-sm border rounded-lg' placeholder='Second name' required></input>
                  </div>
                </div>
                <div className="flex">
                  <div className='pt-2 flex-1 mr-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Email Address</div>
                    <input type='email' name="email" onChange={(ev)=>setemail(ev.target.value)} value={email}
                    className='w-full text-sm border rounded-lg' placeholder='Email address of the user' required></input>
                  </div>
                  <div className='pt-2 flex-1 mr-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Phone Number </div>
                    <input type='email' name="email" onChange={(ev)=>setPhone(ev.target.value)} value={phone}
                    className='w-full text-sm border rounded-lg' placeholder='Phone' required></input>
                  </div>
                </div>

                  {/* <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Password</div>
                    <input type='password' name="password" onChange={(ev)=>setpassword(ev.target.value)}
                     className='w-full text-sm border rounded-lg' placeholder='Password' required></input>
                  </div> */}
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Role</div>
                    <Select className='w-full text-sm border rounded-lg' value={roles}
                      placeholder="Select role" searchable data={roleslist} onChange={(e:string) => setroles(e)} />
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Designation</div>
                    <input type='text' name="designation" onChange={(ev)=>setDesignation(ev.target.value)} value={designation}
                    className='w-full text-sm border rounded-lg' placeholder='Designation of user' required></input>
                  </div>
                  {curentUserroles==rootRoleid?<>
                  <div className="">
                    <div className='pt-2  flex-1'>
                      <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Organization</div>
                      <MultiSelect className='w-full text-sm border rounded-lg' value={orgName}
                        placeholder="Select Organization"  data={allOrg} onChange={(e) => selectedWebsites(e,'second')} />
                    </div>
                    <div className='pt-2 flex-1'>
                      <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Industry</div>
                      <Select className='w-full text-sm border rounded-lg' value={industry}
                        placeholder="Select Industry" searchable data={industriesList} onChange={(e) => setindustry(e)} />
                    </div>
                  </div>
                  <div className="">
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Apps</div>
                    <div className="flex border rounded p-1">
                      <div className="flex-1 text-sm">
                        <span>Active Response</span>
                        <Checkbox checked={activeResponse}  onChange={() => setActiveResponse(!activeResponse)} />
                      </div>
                      <div className="flex-1 text-sm">
                        <span>Design Editor</span>
                        <Checkbox checked={designEditor}   onChange={() => setDesignEditor(!designEditor)} />
                      </div>
                      <div className="flex-1 text-sm">
                        <span>Ad Manager</span>
                        <Checkbox checked={adManager}   onChange={() => setAdManager(!adManager)} />
                      </div>
                      <div className="flex-1 text-sm">
                        <span>Teams</span>
                        <Checkbox checked={teams}   onChange={() => setTeams(!teams)} />
                      </div>
                    </div>
                  </div>
                  </>:''}
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Website</div>
                    {/* <Select className='w-full text-sm border rounded-lg'  value={website}
                      placeholder="Select Website" searchable data={websiteList} onChange={(e) => setWebsite(e)} /> */}
                      <MultiSelect
                          data={selectedwebsiteList}

                          nothingFound="Nothing found"
                          placeholder="Select Website"
                          onChange={(e) => setWebsite(e)}
                          value={website}
                        />
                  </div>
                  {errors!=''? <div className='pt-2 text-red-400'>{errors}</div>:''}
                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">

                  <AdvButton type="submit"
                    color="green"
                    onClick={() => {uploaddata()}}>
                    Update
                  </AdvButton>
                </div>
              </>}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  </>);
};

export default Tablerow;
