import './footer.module.css';

/* eslint-disable-next-line */
export interface FooterProps {}

export function Footer(props: FooterProps) {
  return (
    <footer className="flex justify-between items-center py-2 px-4">
      <span className="text-gray-400 text-sm flex-1 text-center">
        &copy; Rules Engine. All rights reserved.
      </span>
      <div className="icons flex justify-center items-center"></div>
    </footer>
  );
}

export default Footer;
