import React, { Fragment, useEffect, useState,FunctionComponent } from 'react';

import {
  MenuIcon,
  SearchIcon,
  DownloadIcon,
  PlusIcon,
  BellIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  GlobeIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { router } from '../../routes';
import { Link, useRouteActive } from 'react-typesafe-routes';
import { Dialog, Transition } from '@headlessui/react'
import { AdvButton } from '@adv/ui';
import GroupsTable from './groupsTable';
import { useNotifications } from "@mantine/notifications";
import { Select } from '@mantine/core';
import { FetchAllGroups,CreateGroup } from '@adv/sdk/group';
interface CustomProps {
  websitename: string;
  industryId: string;
}
  const Groups: FunctionComponent<CustomProps> = ({
    websitename,industryId
  }: CustomProps) => {
const [myTime, setMyTime] = useState(new Date());
const [create, setcreate] = useState(false) 
const [search, setsearch] = useState('') 

const [name, setname] = useState('') 
const [industry, setindustry] = useState(industryId) 
const [createdBy, setcreatedBy] = useState('') 

const notifications = useNotifications();
const fieldtypes = [
    { value: 'date', label: 'Date' },
    { value: 'email', label: 'Email' },
    { value: 'number', label: 'Number' },
    { value: 'text', label: 'Text' },
    { value: 'select', label: 'Select' },
  ];
const [propertydata,setpropertydata] = useState<any[]>([
    // {_id:'sdfg45',name:'Customer',createdBy:'Hubspot'},
])
const createGroup = async()=>{
  const uploaddata = {
    name: name,
    createdBy: 'Admin',
    industry: industry,
  };
  const { data } = await CreateGroup(uploaddata);
 console.log(data);
 notifications.showNotification({
  title: "Group Created Successfully!",
  message: "",autoClose: 2000,
  color: 'lime',
  loading: false,
  disallowClose: false
});
 fetchgroups();
}
const fetchgroups = async () => {
  const { data } = await FetchAllGroups();
  setpropertydata(data.data);
  console.log(data);
  setMyTime(new Date());
}
useEffect(() => {
  fetchgroups();

}, []);
return (<>
    <div className='flex bg-gray-200 py-2'>
        <div className='flex-1 flex pl-2'></div>
        <div className='flex-1 text-right pr-3 flex justify-end'>
        <div className='relative'>      
            <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2' 
            onChange={(ev) => setsearch(ev.target.value)}/>
            <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
            </div>
        <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500  pr-7 pl-2 text-xs font-bold h-9 relative ml-2
        hover:bg-black hover:text-white transition-all' onClick={() => {setcreate(true)}}>Create Group
            <PlusIcon className='h-6 absolute top-1.5 right-1'/>
        </button>
        </div>
    </div>
    <div className="flex flex-wrap">
        <table className="table-auto flex-1 mx-3 bg-white rounded-xl overflow-hidden shadow-lg text-left mt-3">
        <span className="hidden">{JSON.stringify(myTime)}</span>
            <thead className='bg-gradient-to-r from-black to-gray-700 text-white '>
                <tr>
                <th  className='py-2 w-10'>
                </th>
                <th  className='py-2 '>Group Name</th>
                <th  className='py-2'>Created At</th>
                <th  className='py-2 w-14'></th>
                </tr>
            </thead>
            <tbody>
            {propertydata?.map((prop,i)=>(<>
            {(prop.name?.toLowerCase().includes(search?.toLowerCase())||prop.creator?.toLowerCase().includes(search?.toLowerCase()))?<>
            {industryId==prop.industry._id?
                <GroupsTable  onupdate={(step) => fetchgroups()} user={prop} industryId={industryId}></GroupsTable>
                :''}
                </>:''}
                
                
            </>))}
             </tbody>
        </table>
    </div> 



    <Transition.Root show={create} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto"  onClose={()=>{setcreate(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                      Create Group
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      <button onClick={() => {setcreate(false)}} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
                <div className='px-4 '>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Group Name</div>
                    <input type='text' name="name"  onChange={(ev)=>setname(ev.target.value)}  className='w-full text-sm border rounded-lg' placeholder='Name of the Group' required></input>
                  </div>
                  {/* <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Created By</div>
                    <input type='text' name="name" onChange={(ev)=>setcreatedBy(ev.target.value)} className='w-full text-sm border rounded-lg' placeholder='Name of creator' required></input>
                  </div> */}
                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">
                  <AdvButton type="submit"
                    color="green"
                    onClick={() => {setcreate(false);createGroup()}}>
                    Save
                  </AdvButton>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root> 
</>)
};

export default Groups;
