import { ApiResult, Campaign } from '@adv/interface';
import { Api } from '@adv/utils/api';
import { stringify } from 'query-string';
const url = `schemas/getRegistries/`;
import Cookies from 'js-cookie';
var token= Cookies.get('token');
const config = {
  headers: { Authorization: `Bearer ${token}` }
};




export const getSchemasByIndustry = async (industryId: string) => {
  try {
    const res = await Api.get(`/schemas/getSchemasByIndustry/${industryId}`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const getEventSchemas = async (industryId: string) => {
  try {
    const res = await Api.get(`/eventbridge/getEventSchemas/${industryId}`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const EventsByIndustry = async (industryId: string) => {
  try {
    const res = await Api.get(`/eventbridge/getIndustryEvents/${industryId}`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const CreateSchema = async (data: any) => {
  try {
    const res = await Api.post(`schemas/createSchema`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const createEventSchema = async (data: any) => {
  try {
    const res = await Api.post(`/eventbridge/createEventSchema`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const UpdateSchema = async ( data: any) => {
  try {
    const res = await Api.put(`/schemas/updateSchema`, data,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const deleteSchema = async (registryName: string,schemaName: string) => {
  try {
    const res = await Api.delete(`schemas/deleteSchema/${registryName}/${schemaName}`,config);
    return {
      status: 200,
      data: res || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const getSchema = async (registryName: string,schemaName: string) => {
  try {
    const res = await Api.get(`/schemas/getSchema/${registryName}/${schemaName}`,config);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};