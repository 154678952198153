
import React, { Fragment, useEffect, useState,FunctionComponent,useRef } from 'react';
import { router } from '../../routes';
import { Link, useRouteActive } from 'react-typesafe-routes';
import { AdvButton } from '@adv/ui';
import { Dialog, Transition } from '@headlessui/react'
import { useNotifications } from "@mantine/notifications";
import { MultiSelect,Select } from '@mantine/core';
import { DeleteGroup ,UpdateGroup} from '@adv/sdk/group';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
interface userdata {
    name: string;
    group: string;
    createdBy: string;
    createdAt: string;
    usedIn: string;
    Options: [];
    id: string;
  }
interface CustomProps {
    user: userdata;
    onupdate:(newind: string) => void;
    industryId:string
  }
const GroupsTable: FunctionComponent<CustomProps> = ({
    user,onupdate,industryId
  }: CustomProps) => {
const [open, setOpen] = useState(false) 
const [block, setblock] = useState(false) 
const [edit, setedit] = useState(false) 
const cancelButtonRef = useRef(null)
const [name, setname] = useState(user.name) 
const [industry, setindustry] = useState(industryId) 
const [createdBy, setcreatedBy] = useState(user.createdBy) 
const fields = [
  { value: 'date', label: 'Date' },
  { value: 'email', label: 'Email' },
  { value: 'number', label: 'Number' },
  { value: 'text', label: 'Text' },
  { value: 'select', label: 'Select' },
];
const notifications = useNotifications();
const DeleteUser = async (a:string) => {
  const { data } = await DeleteGroup(user.id);
  //console.log(data);
  onupdate('hii')
  notifications.showNotification({
    title: "Group Deleted Successfully!",
    message: "",autoClose: 2000,
    color: 'lime',
    loading: false,
    disallowClose: false
  });
  // const { data } = await DeleteCustomer(_id);
  // getallcustomers();
}
const updateGroup = async()=> {
  const uploaddata = {
    name: name,
    createdBy: createdBy,
    industry: industry,
  };
  const { data } = await UpdateGroup(user.id, uploaddata);
 onupdate('hii')
 notifications.showNotification({
  title: "Group Updated Successfully!",
  message: "",autoClose: 2000,
  color: 'lime',
  loading: false,
  disallowClose: false
});
 console.log(data);
 setedit(false);
}

  return (<>
        <tr className='border-b border-gray-300 tablebox hover:bg-gray-300 capitalize'>
          <td className='py-5  pl-2 text-appcolor-dark1'>
            {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" />
            </svg> */}
            {/* <input type="checkbox"/> */}
          </td>
          <td>{user.name}</td>
          <td>{user?.createdAt ?format(new Date(user.createdAt), 'MMMM dd, yyyy'):''}</td>
          <td className='tablecontent flex  pt-1.5  pr-1'>
            <button className="bg-gray-200 text-black text-xs p-2 rounded-lg  shadow-lg ml-1 hover:bg-black hover:text-white transition-colors" 
              onClick={() => setedit(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
            </button>
            <button className="bg-gray-200 text-black text-xs p-2 rounded-lg shadow-lg ml-1 hover:bg-black hover:text-white transition-colors"
             onClick={() => setOpen(true)}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          </td>
        </tr>
        <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg py-4 border  bg-white   text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 ">
                    <Dialog.Title as="h3" className="text-base leading-6 font-medium text-gray-900 text-center">
                      Are you sure you want to delete Property <b>"{user.name}"</b> ?
                    </Dialog.Title>
                    {/* <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this Lead? 
                        This action cannot be undone. 
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className=" px-4 py-3 sm:px-6 flex justify-center">
                <AdvButton
                  outline={true}
                  color="green"
                  className="w-28 mr-2 "
                  onClick={() => setOpen(false)} >
                  Cancel
                </AdvButton>
                <AdvButton
                  color="green"
                  className="w-28"
                  onClick={() => {setOpen(false);DeleteUser('g')}}>
                  Yes
                </AdvButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>  


    
    <Transition.Root show={edit} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={()=>{setedit(false);setblock(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                      Edit Group <b>{user.name}</b> ?
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      <button onClick={() => {setedit(false);setblock(false)}} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
                <div className='px-4 '>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Group Name</div>
                    <input type='text' name="name" value={name} onChange={(ev)=>setname(ev.target.value)} className='w-full text-sm border rounded-lg' placeholder='Name of the Group' required></input>
                  </div>
                  {/* <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Created By</div>
                    <input type='text' name="name" value={createdBy} onChange={(ev)=>setcreatedBy(ev.target.value)} className='w-full text-sm border rounded-lg' placeholder='Name of creator' required></input>
                  </div> */}
                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">
                  <AdvButton type="submit"
                    color="green"
                     onClick={() => updateGroup()}
                   // onClick={onupdate.bind(null, 'hii')}
                    >
                    Update
                  </AdvButton>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root> 
  </>);
};

export default GroupsTable;
