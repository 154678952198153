import { Api } from '@adv/utils/api';
import Cookies from 'js-cookie';
var token= Cookies.get('token');
const config = {
  headers: { Authorization: `Bearer ${token}` }
};
export const FetchAllWorkFlow = async () => {
  const url = `https://mawf.bitbridge.co.jp:8449/api/workflow-list`;
  try {
    const res = await Api.get(`${url}`,config);
    console.log(res);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const WorkFlowList = async (id:string) => {
  const url = `workflow/listByIndustryId`;
  try {
    const res = await Api.get(`${url}/${id}`,config);
    console.log(res);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const WorkFlowDetails = async (id:string) => {
  const url = `workflow`;
  try {
    const res = await Api.get(`${url}/${id}`,config);
    console.log(res);
    return {
      status: res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};
export const executeWorkflow = async (
  data:any
) => {
  const url = `workflow/step-function-exec`;
  try {
    const res = await Api.post(url, data,config);
    return {
      status: +res.status || 200,
      data: res.data || null,
    };
  } catch (error) {
    return { status: 400, error };
  }
};