
import React, { Fragment, useEffect, useState } from 'react';

import { router } from '../../routes';
import { Link, useRouteParams } from 'react-typesafe-routes';
import { getSchemasByIndustry,CreateSchema } from '@adv/sdk/schema';
import { FetchAllregistries,createIndustryRegistry } from '@adv/sdk/registry';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { MultiSelect,Select } from '@mantine/core';
import {  SearchIcon,PlusIcon,ChevronLeftIcon} from '@heroicons/react/solid';
import { AdvButton } from '@adv/ui';
import SchemaCard from './schemaCard';
import { Dialog, Transition } from '@headlessui/react'
import { useNotifications } from "@mantine/notifications";
const Schema = () => {
  const options = useRouteParams(router.IndustryEventsById);
  const [registries, setRegistries] = useState<any[]>([])
  const [schemas, setSchemas] = useState<any[]>([])
  const notifications = useNotifications();
  const [create, setcreate] = useState(false)
  const [search, setsearch] = useState('')
  const [desc,setDesc] = useState('')
  const [SchemaName,setSchemaName] = useState('')
  const [RegistryName,setRegistryName] = useState('')
  const [Content,setContent] = useState('{"vehicle":{"status":"string","Make":"string","make":"string","Model":"string","model":"string","sellingPrice":"string","Year":"string","year":"string"},"customer":{"firstName":"string","lastName":"string","state":"string","zip":"string"},"email":{"status":"string","designid":"string"},"customerId":"string"}')

  const getAllregistries = async () => {
    let newgp = [{label:'No Industries yet',value:''}];
    const { data } = await FetchAllregistries();
    let i=0;
    data?.map((p:any, j:any)=>{
      if(i==0){
        newgp = [{label:p.registryName,value:p.registryName}];
    } else  {
      newgp = [...newgp,{label:p.registryName,value:p.registryName}];
    }
      i++;
    })
    setRegistries(newgp);
    console.log(data);
  }
const getAllschemas = async () => {
  const { data } = await getSchemasByIndustry(options.industryId);
  console.log(data);
  console.log(data);
  setSchemas(data);
}
const createSchema = async()=>{
  // let contenttran = {"vehicle":{"status":"string","Make":"string","make":"string","Model":"string","model":"string","sellingPrice":"string","Year":"string","year":"string"},"customer":{"firstName":"string","lastName":"string","state":"string","zip":"string"},"email":{"status":"string","designid":"string"},"customerId":"string"};
  try {
  let contenttran = JSON.parse(Content);
  console.log(contenttran);
  const uploaddata = {
    SchemaName: SchemaName,
    RegistryName: RegistryName,
    Content: contenttran,
  };
  const { status } = await CreateSchema(uploaddata);
  console.log(status);
  if(status==200){
    notifications.showNotification({
    title: "Schema Created Successfully!",
    message: "",autoClose: 2000,
    color: 'lime',
    loading: false,
    disallowClose: false
  });
  getAllschemas ();
  setcreate(false)
  } else {
    notifications.showNotification({
      title: "Schema Creation Error!",
      message: "",autoClose: 2000,
      color: 'red',
      loading: false,
      disallowClose: false
    });
    setcreate(false)
  }
} catch (e) {
  notifications.showNotification({
    title: "Schema Content Invalid!",
    message: "Not a valid json string",autoClose: 2000,
    color: 'red',
    loading: false,
    disallowClose: false
  });
}

}
useEffect(() => {
  getAllregistries();
  getAllschemas();

}, []);
return (
  <div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
    <div className="bg-gray-800 pt-3">
        <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 p-4 shadow text-2xl ">
            <h3 className="font-bold pl-2 mt-2">Event Schema</h3>
        </div>
    </div>
    <div className='flex bg-gray-200 py-2'>
      <div className='flex-1 flex'>
      <div className='relative'>
        <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2'
          onChange={(ev) => setsearch(ev.target.value)} />
        <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
      </div>

      </div>
      <div className='flex-1  text-right pr-3'>
          <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500  pr-7 pl-2 text-xs font-bold h-9 relative ml-2
          hover:bg-black hover:text-white transition-all' onClick={() => {setcreate(true)}}>Add Event Schema
              <PlusIcon className='h-6 absolute top-1.5 right-1'/>
          </button>
      </div>
    </div>
    <div className="flex flex-wrap">
      {schemas?.length == 0 ?
      'Loading...'
      :<>
        <table className="table-auto flex-1 mx-3 bg-white rounded-xl overflow-hidden shadow-lg text-left mt-3">
          <thead className='bg-gradient-to-r from-black to-gray-700 text-white '>
            <tr>
              <th  className='py-2 w-10'>
              </th>
              <th  className='py-2 '>Event Schema</th>
              <th  className='py-2'>ARN</th>
              <th  className='py-2 w-14'></th>
            </tr>
          </thead>
          <tbody>
            {schemas!=undefined?
            schemas.map((reg:any,i:any)=>(

            <SchemaCard  onupdate={() => getAllschemas()} reglist={registries}  registries={reg} ></SchemaCard>
            )
            )
            :''}
          </tbody>
        </table>
        {/* {industries.map((industry,i)=>(<>
          {(industry.name?.toLowerCase().includes(search?.toLowerCase()))?<>
            <IndustryCard  onupdate={(step) => getallIndustries()} industrydata={industry} />
          </>:''}
        </> ))} */}
      </>}
    </div>
    <Transition.Root show={create} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto"  onClose={()=>{setcreate(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                      Create Event Schema
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      <button onClick={() => {setcreate(false);}} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
                <div className='px-4 '>
                <div className='pt-2'>
                  <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Event Schema</div>
                  <input type='text' name="name"  onChange={(ev)=>setSchemaName(ev.target.value)} className='w-full text-sm border rounded-lg'
                  placeholder='Add Event Schema' required></input>
                </div>
                <div className='pt-2'>
                  <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Registry Name</div>

                   {registries[0]?.value==''?'':
                      <Select className='border border-gray-200 rounded-md text-xs w-full'
                      placeholder="Select Registry" searchable data={registries}  onChange={(ev)=>setRegistryName(ev!)} />
                    }
                </div>
                <div className='pt-2'>
                  <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg '>Content</div>
                  <textarea  name="Content"  onChange={(ev)=>setContent(ev.target.value)} className='w-full text-sm border rounded-lg h-60'
                   required>{Content}</textarea>
                </div>
                  {/* <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Industry</div>
                    <Select className='w-full text-sm border rounded-lg'
                      placeholder="Select Industry" searchable data={industries} onChange={(e) => setindustry(e)} />
                  </div> */}
                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">
                  <AdvButton type="submit"
                    color="green"
                     onClick={() => createSchema()}>
                    Create
                  </AdvButton>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  </div>
);
};

export default Schema;
