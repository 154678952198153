
import React, { Fragment, useEffect, useState } from 'react';

import {
  MenuIcon,
  ChevronLeftIcon,
  DownloadIcon,
  UserAddIcon,
  BellIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  GlobeIcon,
  UserIcon,
} from '@heroicons/react/solid';
import { router } from '../../routes';
import { Link, useRouteParams } from 'react-typesafe-routes';
import { Tabs,Tab } from '@mantine/core';
import Properties from './properties';
import Groups from './groups';
const Industrymetadata = () => {
  const options = useRouteParams(router.providers);
const [activeTab, setActiveTab] = useState(0);
  return (
<div className="main-content flex-1  pb-24 md:pb-5 mt-12">
  <div className="bg-gray-800 pt-3">
      <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 py-4 shadow text-2xl flex">
        {/* <button onClick={() => history.back()} className="group cursor-pointer ">
           <ChevronLeftIcon className="text-black-300 group-hover:text-gray-600 h-7 w-7 mt-2 ml-4" />
        </button> */}
        <h3 className="font-bold pl-2 mt-2">{options.name} MetaData</h3>
      </div>
  </div>
    <Tabs active={activeTab} onTabChange={setActiveTab}>
      <Tab label="Properties" className='font-bold'>
        <Properties industryId={options.id} industryname={options.name}/>
      </Tab>
      <Tab label="Groups"  className='font-bold'>
        <Groups industryId={options.id} industryname={options.name}/>
      </Tab>
      <Tab label="Archived Properties"  className='font-bold'>
        {/* <Properties /> */}
        <h2 className='text-center text-4xl mt-10 font-bold text-gray-200 '>No data </h2>
      </Tab>
    </Tabs>
</div>
  );
};

export default Industrymetadata;
