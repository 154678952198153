
import React, { Fragment, useEffect, useState } from 'react';

import { router } from '../../routes';
import { Link, useRouteActive } from 'react-typesafe-routes';
import { FetchAllIndustries,CreateIndustry } from '@adv/sdk/Industry';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import {  SearchIcon,PlusIcon} from '@heroicons/react/solid';
import { AdvButton } from '@adv/ui';
import IndustryCard from './industrycard';
import { Dialog, Transition } from '@headlessui/react'
import { useNotifications } from "@mantine/notifications";
const Industries = () => {
  const [industries, setIndustries] = useState<any[]>([])
  const notifications = useNotifications();
  const [create, setcreate] = useState(false)
  const [search, setsearch] = useState('') 
  const [name,setName] = useState('')
  const [desc,setDesc] = useState('')

const getallIndustries = async () => {
  const { data } = await FetchAllIndustries();
  setIndustries(data);
  console.log(data);
}
const createIndus = async()=>{
  const uploaddata = {
    name: name,
    desc: desc,
  };
  const { data } = await CreateIndustry(uploaddata);
  console.log(data);
  notifications.showNotification({
    title: "Industry Created Successfully!",
    message: "",autoClose: 2000,
    color: 'lime',
    loading: false,
    disallowClose: false
  });
  getallIndustries();
}
useEffect(() => {
  getallIndustries();

}, []);
return (
  <div className="main-content flex-1 bg-gray-100  pb-24 md:pb-5 mt-12">
    <div className="bg-gray-800 pt-3">
        <div className=" bg-gradient-to-r from-appcolor-dark1 to-appcolor-dark4 p-4 shadow text-2xl flex">
            <h3 className="font-bold pl-2 mt-2">Industries</h3>
            <div className='flex-1 text-right'>
                {/* <Link  to={router.IndustryEvents()} 
                className='relative top-2 mr-2 text-lg bg-black text-white rounded-md px-2 py-1 hover:bg-gray-800 transition-all'>
                  Events
                  </Link> */}
                  <Link  to={router.IndustryRegistry()} 
                className='relative top-2 mr-2 text-lg bg-black text-white rounded-md px-2 py-1 hover:bg-gray-800 transition-all'>
                  Industry Registry 
                  </Link>
                  </div>
        </div>
    </div>
    <div className='flex bg-gray-200 py-2'>
      <div className='flex-1 flex'>
      <div className='relative'>      
        <input  placeholder="Search" className='ml-2 border border-gray-200 rounded-md text-xs w-56 h-9 pl-2'
          onChange={(ev) => setsearch(ev.target.value)} />
        <SearchIcon  className='h-6 absolute right-1 top-1.5 text-gray-500'/>
      </div>
        
      </div>
      <div className='flex-1  text-right pr-3'>
          {/* <input type='date' className='ml-2 border border-gray-200 rounded-md text-xs'></input> */}
          <button className='bg-white border shadow-lg p-1 rounded-lg  text-gray-500  pr-7 pl-2 text-xs font-bold h-9 relative ml-2
          hover:bg-black hover:text-white transition-all' onClick={() => {setcreate(true)}}>Create Industry
              <PlusIcon className='h-6 absolute top-1.5 right-1'/>
          </button>
      </div>
    </div>
    <div className="flex flex-wrap">
      {industries?.length == 0 ?
      'Loading...'
      :<>
      
        {industries.map((industry,i)=>(<>
          {(industry.name?.toLowerCase().includes(search?.toLowerCase()))?<>
            <IndustryCard  onupdate={(step) => getallIndustries()} industrydata={industry} />
          </>:''}
        </> ))}
      </>}
    </div>
      <Transition.Root show={create} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto"  onClose={()=>{setcreate(false)}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg  border  bg-white   text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className=" px-4  py-4   bg-gray-200">
                  <div className="relative">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 ">
                      Create Industry.
                    </Dialog.Title>
                    <div className='absolute top-0 right-2'>
                      <button onClick={() => {setcreate(false);}} className=' hover:text-appcolor-dark3 ' >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    </div>
                  </div>
              </div>
                <div className='px-4 '>
                <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Industry Name</div>
                    <input type='text' name="name"  onChange={(ev)=>setName(ev.target.value)} className='w-full text-sm border rounded-lg' placeholder='Name of the Industry' required></input>
                  </div>
                  <div className='pt-2'>
                    <div className='bg-gradient-to-r from-black to-gray-600 text-white inline px-3 py-1 relative left-5 text-xs rounded-t-lg'>Description</div>
                    <input type='text' name="name"  onChange={(ev)=>setDesc(ev.target.value)} className='w-full text-sm border rounded-lg' placeholder='Description of  the Industry' required></input>
                  </div>
                </div>
                <div className=" px-4 py-3 sm:px-6 flex justify-center">
                  <AdvButton type="submit"
                    color="green"
                     onClick={() => createIndus()}
                   // onClick={onupdate.bind(null, 'hii')}
                    >
                    Update
                  </AdvButton>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  </div>
);
};

export default Industries;
