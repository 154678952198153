import React, { HTMLProps } from 'react';
import classNames from 'classnames';
import { Loader } from '@mantine/core';

interface CustomProps {
  color: 'green' | 'yellow' | 'blue' | 'dark' | 'transparent';
  isLoading?: boolean;
  outline?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const colorClasses = {
  green:
    'bg-appcolor-dark3 hover:bg-opacity-60 focus:ring-appcolor-dark2 text-appcolor-light1 hover:text-appcolor-light2',
  yellow:
    'bg-primary-900 hover:bg-opacity-80 focus:ring-yellow-400 text-secondary-400 hover:text-secondary-600',
  blue: 'bg-blue-400 hover:bg-opacity-80 focus:ring-blue-400 text-blue-900 hover:text-blue-800',
  transparent:
    'bg-white hover:bg-opacity-80  text-secondary-500 hover:text-secondary-600 focus:ring-secondary-600',
  dark: 'bg-secondary-500 hover:bg-opacity-80  focus:ring-secondary-600 text-white',
};

const colorOutlineClasses = {
  green:
  'bg-appcolor-light1 hover:bg-opacity-80 focus:ring-appcolor-dark2 text-appcolor-dark3 hover:text-appcolor-light3 border-2 border-appcolor-dark3',
  yellow:
    'bg-primary-50 hover:bg-opacity-80 focus:ring-yellow-400 text-yellow-600 hover:text-yellow-800 border-2 border-yellow-300',
  blue: 'bg-blue-50 hover:bg-opacity-80 focus:ring-blue-400 text-blue-600 hover:text-blue-800 border-2 border-blue-500',
  dark: 'bg-white hover:bg-opacity-80 focus:ring-secondary-500 text-secondary-500 hover:text-secondary-500 border-2 border-secondary-500',
  transparent: 'bg-white',
};

function pick(obj: any, keys: string[]) {
  return keys
    .map((k) => (k in obj ? { [k]: obj[k] } : {}))
    .reduce((res, o) => Object.assign(res, o), {});
}

const AdvButton = (props: CustomProps & React.HTMLProps<HTMLButtonElement>) => {
  return (
    <button
      type={props.type}
      onClick={props?.onClick}
      {...pick(props, Object.keys(HTMLButtonElement))}
      className={classNames(
        'relative group text-sm font-semibold px-4 py-2 rounded shadow hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2',
        props.outline
          ? colorOutlineClasses[props.color]
          : colorClasses[props.color],
        { 'w-full': !props.className },
        props.className
      )}
    >
      {props.children}
      {props.isLoading ? (
        <div className="absolute right-0 inset-y-0 flex items-center pr-3">
          <Loader color="orange" variant="dots" />
        </div>
      ) : (
        ''
      )}
    </button>
  );
};

export default AdvButton;
