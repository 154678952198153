import React from 'react';

export default function Google() {
  return (
    <g>
      <path
        d="M381.604,270.943c-0.585-58.285,47.526-86.241,49.673-87.647    c-27.032-39.536-69.153-44.961-84.15-45.572c-35.81-3.645-69.92,21.096-88.095,21.096c-18.129,0-46.203-20.566-75.902-20.046    c-39.08,0.585-75.09,22.731-95.201,57.711c-40.577,70.387-10.375,174.71,29.162,231.845c19.334,27.92,42.385,59.346,72.624,58.195    c29.152-1.151,40.158-18.859,75.391-18.859c35.234,0,45.135,18.859,75.968,18.266c31.343-0.567,51.216-28.459,70.395-56.496    c22.185-32.44,31.326-63.848,31.865-65.446C442.656,363.678,382.232,340.526,381.604,270.943z M323.665,99.913    c16.037-19.471,26.904-46.531,23.955-73.464c-23.151,0.94-51.171,15.389-67.784,34.842    c-14.887,17.261-27.909,44.741-24.421,71.189C281.232,134.481,307.572,119.348,323.665,99.913z"
        // style="fill-rule:evenodd;clip-rule:evenodd;fill:#1B1B1B;"
      />
    </g>
  );
}
